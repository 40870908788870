import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import tracking from '../../constants/tracking';

import { setMessage } from '../../store/modules/globals';
import { loadCaisse } from '../../store/modules/caisse';
import { saveForm, saveFormValues, submitForm, goToPrevious, goToNewClaims, goToForm } from '../../store/modules/forms';

import BaseLayout from '../shared/BaseLayout';

import DropdownFormsView from '../../components/forms/DropdownFormsView';

function getBlocState (state) {
  return { visibleForm: state.data.config.visibleForm };
}
@connect(
  getBlocState,
  { setMessage, loadCaisse, saveForm, saveFormValues, submitForm, goToPrevious, goToNewClaims, goToForm },
)
class FormContainer extends React.PureComponent {
  static propTypes = {
    setMessage: PropTypes.func.isRequired,
    loadCaisse: PropTypes.func.isRequired,
  }

  componentDidMount () {
    tracking('init');
  }

  render () {
    const {
      saveForm,
      saveFormValues,
      blocState,
      visibleForm,
      goToPrevious,
      goToNewClaims,
      goToForm,
      submitForm,
    } = this.props;
    return (
      <BaseLayout title="Déclarations de Sinistre">
        <div className="workspace-content">
          <DropdownFormsView
            isAdmin={false}
            onSubmitForm={(formName, values) => {
              // we need to save values form
              // and we need to get caisse (method : saveForm)
              if (formName === 'coordonnesForm') {
                return Promise.resolve()
                .then(() => saveForm(formName, values))
                .then(() => saveFormValues());
              }
              return Promise.resolve()
              .then(() => saveForm(formName, values));
            }}
            onFormChange={formName => saveForm(formName, null)}
            submitForm={values => submitForm(values)}
            visibleForm={visibleForm}
            goToPrevious={goToPrevious}
            goToNewClaims={goToNewClaims}
            goToForm={goToForm}
          />
        </div>
      </BaseLayout>
    );
  }
}

export default withRouter(FormContainer);
