export default {
  entities: {
    forms: {},
    downloadPDFLink: null,
    // accounts: {},
    // options: {},
  },
  loaded: {
    appstorage: false,
    // accounts: false,
    // options: false,
  },
  loading: {
    // accounts: false,
    // options: false,
  },
  filters: {
    // accounts: {
    //   search: '',
    // },
  },
  message: {
    text: '',
    messageType: '',
    delay: 0,
  },
  config: {
    // menuIsTiny: false,
    visibleForm: 0,
  },
};
