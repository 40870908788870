import { CALL_API } from '../Schemas';

const LOAD_CAISSE_REQUEST = 'cdwa/caisse/LOAD_CAISSE_REQUEST';
const LOAD_CAISSE_SUCCESS = 'cdwa/caisse/LOAD_CAISSE_SUCCESS';
const LOAD_CAISSE_FAILURE = 'cdwa/caisse/LOAD_CAISSE_FAILURE';

export const caisseActionsHandlers = {
  [LOAD_CAISSE_SUCCESS]: (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      caisse: action.response.data,
    },
    loaded: {
      ...state.loaded,
      caisse: true,
    },
    loading: {
      ...state.loading,
      caisse: false,
    },
  }),
};

export function loadCaisse (trigramme) {
  return {
    trigramme,
    [CALL_API]: {
      types: [LOAD_CAISSE_REQUEST, LOAD_CAISSE_SUCCESS, LOAD_CAISSE_FAILURE],
      method: 'GET',
      endpoint: `/caisse/${trigramme}`,
    },
  };
}
