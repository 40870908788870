import React from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection } from 'redux-form';
import validate from 'validate.js';
import InputField from '../elements/InputField';
import TextareaField from '../elements/TextareaField';
import CleaveField from '../elements/CleaveField';
import AddressPostalTownFields from './AddressPostalTownFields';
import { MESSAGE_ERRORS } from '../../constants/Config';


const TiersIdentifieFields = ({
  sectionName,
}) => (
  <FormSection
    name={sectionName}
    className="grid col-11"
    data-push-left="off-1"
  >
    <Field
      maxLength="50" name="nom_prenom"
      component={InputField}
      type="text"
      label="Identité (Nom, prénom)"
      className="field col-12"
    />
    <AddressPostalTownFields sectionName="tiers_identifie_address" labelAdress='Adresse' labelCpVille='Code Postal / Ville' />
    <Field
      maxLength="255"
      name="email"
      component={InputField}
      label="Email"
      type="email"
      placeholder="email"
      className="field col-12"
    />
    <Field
      name="telephone"
      component={CleaveField}
      type="tel"
      placeholder=""
      label="Téléphone du déclarant"
      className="field col-12"
      cleaveOptions={{ phone: true, phoneRegionCode: 'FR' }}
    />
    <Field
      maxLength="2000"
      name="assureur"
      component={TextareaField}
      type="text"
      label="Son assureur (Identité, adresse, N° contrat, N° de sinistre...) *"
      placeholder="Son assureur (Identité, adresse, N° contrat, N° de sinistre...)"
      className="field field-textarea col-12"
    />
  </FormSection>
);

TiersIdentifieFields.propTypes = {
  sectionName: PropTypes.string.isRequired,
};

TiersIdentifieFields.validate = (values = {}, sectionName) => {
  const errors = {
    [sectionName]: {
      ...validate(values[sectionName], {
        nom_prenom: {
          length: { maximum: 50, tooLong: MESSAGE_ERRORS.max },
        },
        telephone: {
          format: {
            pattern: /^(0[1-9]|\+[0-9]{2,5})( [0-9]{2}){4}/,
            message: "^Le numero de téléphone n'est pas valide",
          },
        },
        email: {
          email: { message: '^Merci de renseigner une adresse email valide' },
          length: { maximum: 255, tooLong: MESSAGE_ERRORS.max },
        },
        assureur: {
          presence: { message: '^Merci de préciser son assureur' },
          length: { maximum: 255, tooLong: MESSAGE_ERRORS.max },
        },
      }),
    },
  };
  return errors;
};

export default TiersIdentifieFields;
