import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import api from './middleware/api';
import rootReducer from './modules/reducer';

const logger = createLogger();

const finalCreateStore = composeWithDevTools(compose(
  applyMiddleware(thunk, api),
  applyMiddleware(logger),
))(createStore);

export default function configureStore (initialState) {
  const store = finalCreateStore(rootReducer, initialState);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./modules/reducer', () => {
      const nextRootReducer = require('./modules/reducer'); // eslint-disable-line
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
