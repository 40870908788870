import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Cleave from 'cleave.js/react';
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.fr';

import FieldError from './FieldError';

const CleaveField = ({
  input, className, id, label, fontIcon, placeholder, disabled, autoFocus, meta, cleaveOptions, maxLength,
}) => (
  <div className={classNames(className, { 'read-only': disabled })}>
    <FieldError {...meta} />
    <Cleave
      {...input}
      id={id || (input && input.name) || 'input'}
      placeholder={placeholder}
      disabled={disabled ? 'disabled' : ''}
      autoComplete="off"
      autoFocus={autoFocus || false}
      autoCapitalize="none"
      options={cleaveOptions}
      maxLength={maxLength}
    />
    {label &&
      <label htmlFor={id || (input && input.name) || 'input'}>
        {fontIcon &&
          <i className={classNames(fontIcon)} />
        }
        {label}
      </label>
    }
  </div>
);

CleaveField.propTypes = {
  input: PropTypes.shape(),
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  fontIcon: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  meta: PropTypes.shape(),
  cleaveOptions: PropTypes.shape(),
};

export default CleaveField;
