import React from 'react';
import PropTypes from 'prop-types';

import FieldError from '../elements/FieldError';

const FieldArrayTabForm = ({
  fields,
  removable,
  meta,
  ComponentField,
  addLabel,
  componentProps,
}) => (
  <div className="col-12">
    <table>
      <thead>
        <tr>
          <th>
            Contenu
          </th>
          <th className="cell-date">
            Date d'achat
          </th>
          <th>
            Descriptif succinct du bien et des dommages
          </th>
        </tr>
      </thead>
      <tbody>
        <tr colSpan="3">
          <FieldError {...meta} />
        </tr>
        { fields.map((field, index) => (
          <ComponentField
            field={field}
            onClickRemove={removable ? () => fields.remove(index) : null}
            fieldIndex={index}
            {...componentProps}
          />
        ))}
      </tbody>
    </table>
    {addLabel && (
      <div className="btn-group right">
        <button type="button" className="btn" onClick={() => fields.push({})}>
          {addLabel}
        </button>
      </div>
    )}
  </div>
);

FieldArrayTabForm.propTypes = {
  fields: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  removable: PropTypes.bool,
  ComponentField: PropTypes.func.isRequired,
  addLabel: PropTypes.string.isRequired,
  componentProps: PropTypes.shape().isRequired,
};

export default FieldArrayTabForm;
