import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FieldError from './FieldError';
import WysiwygTextarea from './WysiwygTextarea';

const TextareaField = ({ input, className, id, label, fontIcon, placeholder, disabled, autoFocus, meta, isWysiwyg, wysiwygOptions, maxLength }) => (
  <div className={classNames(className, { 'read-only': disabled })}>
    <FieldError {...meta} />
    {isWysiwyg ? (
      <WysiwygTextarea
        text={input.value}
        onBlur={v => input.onChange(v)}
        placeholder={placeholder}
        options={wysiwygOptions}
      />
    ) : (
      <textarea
        {...input}
        id={id || (input && input.name) || 'textarea'}
        placeholder={placeholder}
        disabled={disabled ? 'disabled' : ''}
        autoFocus={autoFocus || false}
        maxLength={maxLength}
      />
    )}
    {label &&
      <label htmlFor={id || (input && input.name) || 'textarea'}>
        {fontIcon &&
          <i className={classNames(fontIcon)} />
        }
        {label}
      </label>
    }
  </div>
);

TextareaField.propTypes = {
  input: PropTypes.shape(),
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  fontIcon: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  meta: PropTypes.shape(),
  isWysiwyg: PropTypes.bool,
  wysiwygOptions: PropTypes.arrayOf(PropTypes.string),
};

export default TextareaField;
