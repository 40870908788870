import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import FieldError from './FieldError';

import { DATE_FORMAT } from '../../constants/Config';

const DateField = ({
  input,
  className,
  id,
  label,
  fontIcon,
  placeholder,
  disabled,
  autoFocus,
  meta,
}) => (
  <div className={classNames(className, { 'read-only': disabled })}>
    <FieldError {...meta} />
    <DatePicker
      onChange={d => input.onChange(d ? d.format(DATE_FORMAT) : null)}
      id={id || (input && input.name) || 'input'}
      placeholderText={placeholder}
      dateForm={DATE_FORMAT}
      disabled={disabled}
      autoFocus={autoFocus || false}
      selected={input.value ? moment(input.value, DATE_FORMAT) : null}
      className="datepicker"
    />
    {label &&
      <label htmlFor={id || (input && input.name) || 'input'}>
        {fontIcon &&
          <i className={classNames(fontIcon)} />
        }
        {label}
      </label>
    }
    {input.value && (
      <i className="fi fi-cross input-cross" onClick={() => input.onChange(null)} />
    )}
  </div>
);

DateField.propTypes = {
  input: PropTypes.shape(),
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  fontIcon: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  meta: PropTypes.shape(),
};

export default DateField;
