const SET_FILTER = 'htwp/globals/SET_FILTER';
const SET_MESSAGE = 'htwp/globals/SET_MESSAGE';
const SET_CONFIG = 'htwp/globals/SET_CONFIG';

export const globalsActionsHandlers = {
  [SET_FILTER]: (state, action) => ({
    ...state,
    filters: {
      ...state.filters,
      [action.filterType]: {
        ...state.filters[action.filterType],
        [action.name]: action.value,
      },
    },
  }),
  [SET_MESSAGE]: (state, action) => ({
    ...state,
    message: {
      text: action.text,
      messageType: action.messageType,
      delay: action.delay,
    },
  }),
  [SET_CONFIG]: (state, action) => ({
    ...state,
    config: {
      ...state.config,
      [action.key]: action.value,
    },
  }),
};

export function setFilter (filterType, name, value) {
  return {
    type: SET_FILTER,
    filterType,
    name,
    value,
  };
}

export function setMessage (text = '', messageType = 'error', delay = 0) {
  return {
    type: SET_MESSAGE,
    text,
    messageType,
    delay,
  };
}

export function setConfig (key, value) {
  return {
    type: SET_CONFIG,
    key,
    value,
  };
}
