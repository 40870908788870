import fetch from "isomorphic-fetch";
import { API_URL, MESSAGE_ERRORS } from "./Config";

export const wa_gfr_init = {
  contenu: {
    fil_ariane: "",
    marche: "",
    region_enregistree: ""
  },
  contact: {
    origine: ""
  },
  formulaire: {
    decla_sinistre: {
      type_collectivite: "",
      nom_collectivite: "",
      fonction_interlocuteur: "",
      nom_interlocuteur: "",
      code_postal_sinistre: "",
      commune_sinistre: "",
      pv_gendarmerie: "",
      depot_plainte: "",
      type_sinsitre: "",
      type_contrat: "",
      dab: {
        type_dommage: "",
        tiers_cause: "",
        type_tiers: "",
        estimation_dommage: "",
        type_declarant: "",
        contenu_endommage: ""
      },
      pj: {
        dommage_provoque: "",
        tiers_identifie: "",
        vehicule_concerne: ""
      },
      rc: {
        dommage_subi: "",
        type_dommage: "",
        dommage_provoque: "", // oui ou non
        tiers_identifie: "", // oui ou non
        vehicule_concerne: "" // oui ou non
      },
      auto: {
        type_sinsitre: ""
      }
    }
  },
  devis: {
    commun: {
      hash_email_sn: "", // scripte en AES 128
      hash_phone_sn: "", // scripte en AES 128
      code_postal: "",
      commune: "",
      est_client: ""
    }
  },
  outils_an: {
    identifiant_crm: "" // scripte en AES 128
  }
};

const TYPE_SINISTRE_track = {
  Dommage_aux_biens: "Dommage aux biens",
  Responsabilite_civile: "Responsabilite civile",
  Protection_juridique: "Protection juridique",
  Automobile: "Automobile"
};

const TYPE_SINISTRE_AUTO_track = {
  Accident: "Accident",
  Tentative_de_vol: "Tentative de vol",
  Vol: "Vol",
  Incendie: "Incendie",
  Evenement_naturel: "Evenement naturel",
  Vandalisme: "Vandalisme"
};

const TYPE_CONTRAT_track = {
  Mission: "Mission",
  Flotte: "Flotte",
  Autre: "Autre"
};

const TYPE_DOMMAGE_track = {
  Incendie: "Incendie",
  Dommages_electriques: "Dommages electriques",
  Vol: "Vol",
  Tempete: "Tempete",
  Degats_des_eaux: "Degats des eaux",
  Bris_de_glace: "Bris de glace",
  other: "Autre"
};

const MONTANT_DOMMAGE_track = {
  Inf_1000: "moins 1000",
  "1000_a_3000": "1000-3000",
  "3000_a_15000": "3000-15000",
  Sup_15000: "plus de 15000"
};

const ETES_VOUS_track = {
  Propriétaire_occupant: "Proprietaire occupant",
  Propriétaire_non_occupant: "Proprietaire non occupant",
  Locataire: "Locataire",
  Co_proprietaire: "Co-proprietaire"
};

const tracking = (stepName, values = {}) => {
  const body = {
    email: values.email,
    telephone: values.telephone
  };

  switch (stepName) {
    case "init":
      window.wa_gfr.contenu.fil_ariane =
        "Formulaire Declaration Sinistre::Etape 1 Vos Coordonnees";
      window.wa_gfr.contenu.marche = "particulier";
      window.wa_gfr.contenu.region_enregistree = "LBR";
      window.wa_gfr.contact.origine = "site marchand";
      window._satellite.track("glb-declasinistre-e1");
      window._satellite.pageBottom();
      break;
    case "coordonnesForm":
      // Send request to crypt values
      // on verifie que lidentifiant existe si oui on lenvoi a la crypto
      if (values.identification.identifiant_client !== undefined) {
        body.identifiant = values.identification.identifiant_client;
      }
      fetch(`${API_URL}/claims/trackingencrypt`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          if (response.status >= 400) {
            return "Bad response from server";
          }
          return response.json();
        })
        .then(results => {
          window.wa_gfr = {
            ...wa_gfr_init,
            contenu: {
              fil_ariane:
                "Formulaire Declaration Sinistre::Etape 2 Votre Sinistre",
              marche: "particulier",
              region_enregistree: "LBR"
            },
            contact: {
              origine: "site marchand"
            },
            decla_sinistre: {
              ...wa_gfr_init.decla_sinistre,
              type_collectivite: values.collectivite,
              nom_collectivite: values.nom_collectivite,
              fonction_interlocuteur: values.interlocuteur,
              nom_interlocuteur: values.nom_interlocuteur
            },
            devis: {
              commun: {
                ...wa_gfr_init.devis.commun,
                hash_email_sn: results.data.email, // scripte en AES 128
                hash_phone_sn: results.data.telephone, // scripte en AES 128
                code_postal:
                  values.identification.localisation_coordonnes !== undefined
                    ? values.identification.localisation_coordonnes
                        .code_postal_city
                    : "",
                commune:
                  values.identification.localisation_coordonnes !== undefined
                    ? values.identification.localisation_coordonnes
                        .code_postal_city.ville
                    : "",
                est_client:
                  values.identification.choice === "true" ? "oui" : "non"
              }
            },
            outils_an: {
              identifiant_crm:
                results.data.identifiant !== undefined
                  ? results.data.identifiant
                  : "" // scripte en AES 128
            }
          };
          window._satellite.track("glb-declasinistre-e2");
          window._satellite.pageBottom();
        });
      break;

    case "sinistreForm":
      window.wa_gfr = {
        ...wa_gfr_init,
        contenu: {
          fil_ariane: "Formulaire Declaration Sinistre::Etape 3 Vos Dommages",
          marche: "particulier",
          region_enregistree: "LBR"
        },
        contact: {
          origine: "site marchand"
        },
        decla_sinistre: {
          ...wa_gfr_init.decla_sinistre,
          code_postal_sinistre:
            values.localisation_sinistre.code_postal_city.value,
          commune_sinistre: values.localisation_sinistre.code_postal_city.ville,
          depot_plainte:
            values.oui_non_plainte.choice === "true" ? "oui" : "non",
          type_sinsitre: TYPE_SINISTRE_track[values.sinistre_concerne.choice],
          type_contrat: TYPE_CONTRAT_track[values.sinistre_concerne.Contrat]
        }
      };
      window._satellite.track("glb-declasinistre-e3");
      window._satellite.pageBottom();
      break;

    case "rcpj":
      window.wa_gfr = {
        ...wa_gfr_init,
        contenu: {
          fil_ariane: "Formulaire Declaration Sinistre::Etape 4.1 Validation",
          marche: "particulier",
          region_enregistree: "LBR"
        },
        contact: {
          origine: "site marchand"
        }
      };
      // pour verifier que c'est RC
      if (values.type_dommage_provoque !== undefined) {
        window.wa_gfr.formulaire.decla_sinistre.rc = {
          dommage_subi:
            values.oui_non_subi_dommage.choice === "true" ? "oui" : "non",
          type_dommage: values.type_dommage_provoque[0],
          tiers_identifie:
            values.oui_non_tiers_identifie.choice === "true" ? "oui" : "non", // oui ou non
          vehicule_concerne:
            values.oui_non_vehicule_dentifie.choice === "true" ? "oui" : "non" // oui ou non
        };
      } else {
        window.wa_gfr.formulaire.decla_sinistre.pj = {
          dommage_subi:
            values.oui_non_subi_dommage.choice === "true" ? "oui" : "non",
          tiers_identifie:
            values.oui_non_tiers_identifie.choice === "true" ? "oui" : "non", // oui ou non
          vehicule_concerne:
            values.oui_non_vehicule_dentifie.choice === "true" ? "oui" : "non" // oui ou non
        };
      }
      window._satellite.track("glb-declasinistre-e4-1");
      window._satellite.pageBottom();
      break;

    case "DommagesAuxBiensForm":
      window.wa_gfr = {
        ...wa_gfr_init,
        contenu: {
          fil_ariane: "Formulaire Declaration Sinistre::Etape 4.1 Validation",
          marche: "particulier",
          region_enregistree: "LBR"
        },
        contact: {
          origine: "site marchand"
        },
        decla_sinistre: {
          ...wa_gfr_init.decla_sinistre,
          dab: {
            type_dommage: TYPE_DOMMAGE_track[values.dommageConcerne.choice],
            tiers_cause:
              values.oui_non_tiers_en_cause.choice === "true" ? "oui" : "non",
            estimation_dommage: MONTANT_DOMMAGE_track[values.montant_dommage],
            type_declarant: ETES_VOUS_track[values.etes_vous.choice],
            contenu_endommage:
              values.oui_non_contenu_endommage.choice === "true" ? "oui" : "non"
          }
        }
      };
      window._satellite.track("glb-declasinistre-e4-1");
      window._satellite.pageBottom();
      break;

    case "automobilesForm":
      window.wa_gfr = {
        ...wa_gfr_init,
        contenu: {
          fil_ariane: "Formulaire Declaration Sinistre::Etape 4.1 Validation",
          marche: "particulier",
          region_enregistree: "TEST"
        },
        contact: {
          origine: "site marchand"
        },
        decla_sinistre: {
          ...wa_gfr_init.decla_sinistre,
          auto: {
            type_sinsitre:
              TYPE_SINISTRE_AUTO_track[values.dommageConcerne.choice]
          }
        }
      };
      window._satellite.track("glb-declasinistre-e4-1");
      window._satellite.pageBottom();
      break;

    case "validationForm":
      window.wa_gfr = {
        ...wa_gfr_init,
        contenu: {
          fil_ariane: "Formulaire Declaration Sinistre::Etape 4.2 Validation",
          marche: "particulier",
          region_enregistree: "LBR"
        },
        contact: {
          origine: "site marchand"
        }
      };
      window._satellite.track("glb-declasinistre-e4-2");
      break;
    default:
      break;
  }
};

export default tracking;
