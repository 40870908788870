import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FieldError from './FieldError';

const MultiCheckboxField = ({
  input, classNameGroup, className, id, label, fontIcon, disabled, autoFocus, meta, options,name
}) => (
  <div className={classNames(classNameGroup, { 'read-only': disabled })}>
    <FieldError {...meta} />
    <div className="field-radio-options">
      {options.map(option => (
        <div className={className} key={option.value}>
          <input
            id={`multi_cb_${id}_${option.value}`}
            value={option.value}
            onChange={(e) => {
              const values = input.value || [];
              const indexElem = values.indexOf(e.target.value);
              if (e.target.checked === false && indexElem >= 0) {
                return input.onChange(values.filter((v, i) => i !== indexElem));
              }
              return input.onChange(values.concat(e.target.value));
            }}
            type="checkbox"
            checked={Array.isArray(input.value) && input.value.indexOf(option.value) >= 0}
            disabled={disabled ? 'disabled' : ''}
            name={name}
          />
          <label className="label-option" htmlFor={`multi_cb_${id}_${option.value}`}>
            {option.label}
          </label>
        </div>
      ))}
    </div>
    {label &&
      <label>
        {fontIcon &&
          <i className={classNames(fontIcon)} />
        }
        {label}
      </label>
    }
  </div>

);

MultiCheckboxField.propTypes = {
  input: PropTypes.shape(),
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  classNameGroup: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
      PropTypes.shape().isRequired,
    ]),
    label: PropTypes.string.isRequired,
  })).isRequired,
  meta: PropTypes.shape(),
};

export default MultiCheckboxField;
