import { CALL_API } from '../Schemas';

const UPLOADS_REQUEST = 'cdwa/uploads/UPLOADS_REQUEST';
const UPLOADS_SUCCESS = 'cdwa/uploads/UPLOADS_SUCCESS';
const UPLOADS_FAILURE = 'cdwa/uploads/UPLOADS_FAILURE';
const DELETE_REQUEST = 'cdwa/uploads/DELETE_REQUEST';
const DELETE_SUCCESS = 'cdwa/uploads/DELETE_SUCCESS';
const DELETE_FAILURE = 'cdwa/uploads/DELETE_FAILURE';

export const uploadsActionsHandlers = {
  [UPLOADS_SUCCESS]: (state, action) => (
    console.log(' ', action)
  ),
  [UPLOADS_FAILURE]: (state, action) => (
    console.log('FAILLUUURE', action)
  ),
};

export function uploadFiles (files, fileName, mimetype) {
  return {
    [CALL_API]: {
      types: [UPLOADS_REQUEST, UPLOADS_SUCCESS, UPLOADS_FAILURE],
      method: 'POST',
      endpoint: '/uploads',
      body: {
        file: {
          fileName,
          mimetype,
        },
        files: Array.isArray(files) ? files : [files],
      },
      upload: true,
    },
  };
}

export function removeFile (filesToRemove) {
  return {
    [CALL_API]: {
      types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
      method: 'DELETE',
      endpoint: '/uploads',
      body: {
        filesToRemove,
      },
      upload: true,
    },
  };
}
