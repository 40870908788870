import React from 'react';
import { Provider } from 'react-redux';
import 'react-select/dist/react-select.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-sweet-progress/lib/style.css';

import configureStore from '../store/configureStore';

import Router from './Router';

import '../../assets/stylesheets/app.scss';

// Import Favicons directory
import '../../public/favicons/android-chrome-144x144.png';
import '../../public/favicons/android-chrome-192x192.png';
import '../../public/favicons/android-chrome-256x256.png';
import '../../public/favicons/apple-touch-icon.png';
import '../../public/favicons/browserconfig.xml';
import '../../public/favicons/favicon-16x16.png';
import '../../public/favicons/favicon-32x32.png';
import '../../public/favicons/favicon.ico';
import '../../public/favicons/app.manifest';
import '../../public/favicons/mstile-150x150.png';
import '../../public/favicons/safari-pinned-tab.svg';

import '../../public/javascript/datalayer';

const store = configureStore();

const App = () => (
  <Provider store={store}>
    <Router />
  </Provider>
);

export default App;
