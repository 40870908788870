import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { map } from 'lodash';
import validate from 'validate.js';
import fetch from 'isomorphic-fetch';

import InputField from '../elements/InputField';
import OtherSection from '../elements/OtherSection';
import OtherSelect from '../elements/OtherSelect';
import RadioField from '../elements/RadioField';
import FileField from '../elements/FileField';
import SelectField from '../elements/SelectField';
import TextareaField from '../elements/TextareaField';
import FieldArrayTabFormBlesses from './FieldArrayTabFormBlesses';
import { formPosition } from '../../store/modules/forms';
import ContenuEndommageFields from './ContenuEndommageFields';
import ContenuBlessesFields from './ContenuBlessesFields';
import {
  MESSAGE_ERRORS,
  OUI_NON,
  API_URL,
  CHOIX_GARANTIE,
  TYPE_TIERS,
  TYPE_DOMMAGE,
  VEHICULE_LOCALISATION,
} from '../../constants/Config';

const AutomobilesForm = ({
  handleSubmit,
  form,
  visibleForm,
  goToPrevious,
  typeSinistre,
  reset,
}) => (
  <form
    className={visibleForm === formPosition.indexOf('dommagesForm') ? 'grid' : 'hidden'}
    onSubmit={handleSubmit}
  >
    <div className='col-12'>
      <OtherSection
        sectionName='dommageConcerne'
        formName={form}
        choiceComponent={RadioField} // Or RadioField or InputField
        label='Votre dommage concerne ? *'
        options={TYPE_DOMMAGE}
        className='field field-radio'
        classNameGroup='field-radio-list field-radio-list-buttons field-radio-list-3-cln col-12'
        sectionClassName='grid'
        otherSections={{}}
      />
    </div>
    <div className='col-12'>
      <Field
        maxLength='2000'
        name='circonstances_evenement'
        placeholder="Décrire l'événement"
        component={TextareaField}
        label="Description détaillée de l'événement * :"
        className='field field-textarea'
      />
    </div>

    <div className='col-12'>
      <div className='question-title'>Conducteur du véhicule au moment de l&apos;événement :</div>
      <div className='question-container'>
        <div className='grid'>
          <OtherSection
            sectionName='conducteur_prenom'
            formName={form}
            placeholder='saisissez'
            choiceComponent={InputField} // Or RadioField or InputField
            label='Prénom * '
            className='field field-inline'
            sectionClassName='col-6'
            otherSections={{}}
          />
          <OtherSection
            sectionName='conducteur_nom'
            formName={form}
            placeholder='saisissez'
            choiceComponent={InputField} // Or RadioField or InputField
            label='Nom * '
            className='field field-inline'
            sectionClassName='col-6'
            otherSections={{}}
          />
        </div>
      </div>
    </div>

    <div className='col-12'>
      <OtherSection
        sectionName='intervention_police'
        formName={form}
        choiceComponent={RadioField} // Or RadioField or InputField
        label='La police ou les pompiers sont-ils intervenus ? *'
        options={OUI_NON}
        className='field field-radio'
        classNameGroup='field-radio-list field-radio-list-styled col-12'
        sectionClassName='grid'
        otherSections={{}}
      />
    </div>

    <div className='col-12'>
      <div className='question-title'>Votre véhicule</div>
      <div className='question-container'>
        <div className='grid'>
          <OtherSection
            sectionName='vehicule_implique_immatriculation'
            formName={form}
            placeholder='saisissez'
            choiceComponent={InputField} // Or RadioField or InputField
            label='Immatriculation *'
            className='field field-inline'
            sectionClassName='col-6'
            otherSections={{}}
          />
        </div>
        <div className='grid'>
          <OtherSection
            sectionName='vehicule_implique_marque'
            formName={form}
            placeholder='saisissez'
            choiceComponent={InputField} // Or RadioField or InputField
            label='Marque *'
            className='field field-inline'
            sectionClassName='col-6'
            otherSections={{}}
          />
          <OtherSection
            sectionName='vehicule_implique_modele'
            formName={form}
            placeholder='saisissez'
            choiceComponent={InputField} // Or RadioField or InputField
            label='Modèle *'
            className='field field-inline'
            sectionClassName='col-6'
            otherSections={{}}
          />
        </div>
      </div>
    </div>
    <div className='col-12'>
      <OtherSection
        sectionName='Dommage_vehicule'
        formName={form}
        choiceComponent={RadioField} // Or RadioField or InputField
        label='Y a-t-il des dommages au véhicule ? *'
        options={OUI_NON}
        className='field field-radio'
        classNameGroup='field-radio-list field-radio-list-styled col-12'
        sectionClassName='grid'
        otherSections={{
          true: () => (
            <div className='grid col-11' data-push-left='off-1'>
              <Field
                maxLength='2000'
                name='description_dommage'
                placeholder='Décrire le dommage '
                component={TextareaField}
                label='Description des dommages pour le véhicule * : '
                className='field field-textarea'
              />
            </div>
          ),
        }}
      />
    </div>

    <div className='col-12'>
      <OtherSection
        sectionName='Constat_amiable'
        formName={form}
        choiceComponent={RadioField} // Or RadioField or InputField
        label="Y a-t-il eu un constat à l'amiable ? *"
        options={OUI_NON}
        sectionClassName='grid'
        classNameGroup='col-12 field-radio-list field-radio-list-styled'
        className='field field-radio'
        otherSections={{
          true: () => (
            <div className='col-11' data-push-left='off-1'>
              <div className='grid'>
                <div className='col-12'>
                  <div className='question-title'>
                    Joindre le constat :{' '}
                    <span className='label-small-italic'>
                      Formats autorisés: jpg, png, gif, pdf. Taille Max: 3 Mo
                    </span>
                  </div>
                </div>
              </div>
              <div className='grid'>
                <div className='col-12'>
                  <div className='dropzone-legend'>
                    Nous vous invitons à nous transmettre une copie du constat amiable (recto/verso)
                    et à conserver l&apos;original pendant une durée de 2 ans.
                  </div>
                </div>
                <div className='col-12'>
                  <Field
                    name='attachments_constat'
                    buttonLabel='Déposer vos fichiers ici'
                    component={FileField}
                    multiple
                    className='field field-dropzone'
                  />
                </div>
              </div>
            </div>
          ),
        }}
      />
    </div>

    <div className='col-12'>
      <OtherSection
        sectionName='depot_plainte'
        formName={form}
        choiceComponent={RadioField} // Or RadioField or InputField
        label='Y a-t-il eu un dépôt de plainte ? *'
        options={OUI_NON}
        className='field field-radio'
        classNameGroup='field-radio-list field-radio-list-styled col-12'
        sectionClassName='grid'
        otherSections={{}}
      />
    </div>

    <div className='col-12'>
      <OtherSection
        sectionName='blesses'
        formName={form}
        choiceComponent={RadioField} // Or RadioField or InputField
        label='Y a-t-il eu des blessés ? *'
        options={OUI_NON}
        className='field field-radio'
        classNameGroup='field-radio-list field-radio-list-styled col-12'
        sectionClassName='grid'
        otherSections={{
          true: () => (
            <FieldArray
              name='tableau_blesses'
              component={FieldArrayTabFormBlesses}
              ComponentField={ContenuBlessesFields}
              removable
              addLabel='+ Ajouter un blessé'
            />
          ),
        }}
      />
    </div>

    <div className='col-12'>
      <OtherSection
        sectionName='presence_tiers'
        formName={form}
        choiceComponent={RadioField} // Or RadioField or InputField
        label="Y a-t-il la présence d'un tiers ? *"
        options={OUI_NON}
        className='field field-radio'
        classNameGroup='field-radio-list field-radio-list-styled col-12'
        sectionClassName='grid'
        otherSections={{
          true: () => (
            <div className='col-11' data-push-left='off-1'>
              <div className='grid'>
                <div className='col-12'>
                  <Field
                    maxLength='2000'
                    name='presence_tiers_identite'
                    placeholder='Identité et adresse du tiers'
                    component={TextareaField}
                    label='Identité et adresse de tiers * '
                    className='field field-inline'
                  />
                </div>
                <div className='col-12'>
                  <Field
                    maxLength='2000'
                    name='presence_tiers_immatriculation'
                    placeholder='Immatriculation du véhicule du tiers'
                    component={InputField}
                    label='Immatriculation du véhicule du tiers *'
                    className='field field-inline'
                  />
                </div>
                <div className='col-12'>
                  <Field
                    maxLength='2000'
                    name='presence_tiers_identite_assureur'
                    placeholder='Son assureur (Identité, adresse, N° contrat, N° de sinistre...)'
                    component={TextareaField}
                    label='Identité et adresse de son assureur *'
                    className='field field-inline'
                  />
                </div>
              </div>
            </div>
          ),
        }}
      />
    </div>
    <div className='col-12'>
      <div className='question-title'>Réparation de votre véhicule :</div>
      <div className='question-container'>
        <OtherSection
          sectionName='reparation_vehicule'
          formName={form}
          placeholder='choisissez'
          choiceComponent={RadioField} // Or RadioField or InputField
          label="Avez-vous fait appel à l'assistance Groupama ? *"
          options={OUI_NON}
          className='field field-radio'
          sectionClassName='col-12'
          classNameGroup='field-radio-list field-radio-list-styled'
          otherSections={{}}
        />
        <OtherSection
          sectionName='vehicule_encore_rouler'
          formName={form}
          choiceComponent={RadioField} // Or RadioField or InputField
          label='Votre véhicule peut-il encore rouler ? *'
          options={OUI_NON}
          className='field field-radio'
          sectionClassName='col-12'
          classNameGroup='field-radio-list field-radio-list-styled'
          otherSections={{}}
        />
        <OtherSection
          sectionName='localisation_vehicule'
          formName={form}
          choiceComponent={SelectField} // Or RadioField or InputField
          placeholder='Sélectionnez'
          label='Où se trouve actuellement votre véhicule ? *'
          options={VEHICULE_LOCALISATION}
          sectionClassName='col-12'
          className='field field-inline'
          otherSections={{
            Autres: () => (
              <div className='question-container'>
                <div className='grid'>
                  <div className='col-12'>
                    <Field
                      name='adresse_autres'
                      maxLength='75'
                      label='Adresse'
                      sectionName='localisation_véhicule_adresse_autres'
                      component={InputField}
                      placeholder='Adresse...'
                      className='field field-inline'
                    />
                  </div>
                  <div className='col-12'>
                    <Field
                      name='code_postal_city_autres'
                      component={SelectField}
                      placeholder='Saisissez votre code postal ou ville'
                      type='number'
                      label='Code Postal / Ville'
                      searchPromptText=''
                      loadingPlaceholder='Chargement...'
                      noResultsText='Aucun résultat trouvé'
                      className='field field-inline'
                      loadOptions={(input, callback) => {
                        if (!input || input.length < 2) return callback([]);
                        fetch(`${API_URL}/claims/postalcodetown/${input}`)
                          .then((response) => {
                            if (response.status >= 400) {
                              return callback('Bad response from server');
                            }
                            return response.json();
                          })
                          .then((results) =>
                            callback(null, {
                              options: map(results.data, (r) => ({
                                value: `${r.codePostal}`,
                                label: `${r.codePostal} - ${r.ville}`,
                                ville: `${r.ville}`,
                              })),
                            })
                          );
                      }}
                    />
                  </div>
                </div>
              </div>
            ),
            Garagiste: () => (
              <div className='question-container'>
                <div className='grid'>
                  <div className='col-12'>
                    <Field
                      name='adresse_garagiste'
                      maxLength='75'
                      label='Adresse'
                      sectionName='localisation_véhicule_adresse_garagiste'
                      component={InputField}
                      placeholder='Adresse...'
                      className='field field-inline'
                    />
                  </div>
                  <div className='col-12'>
                    <Field
                      name='code_postal_city_garagiste'
                      component={SelectField}
                      placeholder='Saisissez votre code postal ou ville'
                      type='number'
                      label='Code Postal / Ville'
                      searchPromptText=''
                      loadingPlaceholder='Chargement...'
                      noResultsText='Aucun résultat trouvé'
                      className='field field-inline'
                      loadOptions={(input, callback) => {
                        if (!input || input.length < 2) return callback([]);
                        fetch(`${API_URL}/claims/postalcodetown/${input}`)
                          .then((response) => {
                            if (response.status >= 400) {
                              return callback('Bad response from server');
                            }
                            return response.json();
                          })
                          .then((results) =>
                            callback(null, {
                              options: map(results.data, (r) => ({
                                value: `${r.codePostal}`,
                                label: `${r.codePostal} - ${r.ville}`,
                                ville: `${r.ville}`,
                              })),
                            })
                          );
                      }}
                    />
                  </div>
                </div>
              </div>
            ),
          }}
        />
        <div className='infos-block'>
          <p>
            Pour la réparation de votre véhicule, votre contrat vous permet de bénéficier du service
            Auto Presto de Groupama :
            <br />
            <br />
            Grâce au service Auto Presto<sup>(1)</sup> de Groupama :
            <br />
            <br />
            - Groupama s'occupe de tout et vous met en relation avec le garage agréé proche de chez
            vous qui vous proposera le service à domicile <br />
            - Vous disposez d'un véhicule de prêt pendant toute la durée des réparations <br />-
            Vous disposez d'une garantie à vie sur les réparations<sup>(2)</sup>
            <br />- Vous n'avancez pas les frais<sup>(3)</sup>, Groupama règle directement le garage
            pour vous.
            <br />
            <br />
            <sup>(1)</sup> Service soumis à conditions. Pour les conditions et limites des
            prestations se reporter au contrat. <br />
            <sup>(2)</sup> Si les réparations consécutives à un accident garanti sont réalisées
            auprès de l'un de nos garages partenaires, ces réparations sont garanties pour la durée
            de vie du véhicule réparé restant assuré chez Groupama. Pour connaître les conditions et
            les limites, se renseigner auprès de nos conseillers Groupama dans les agences des
            départements 22, 29, 35, 44, 49 et 56. Vous disposez de la faculté, en cas de dommages
            garantis par votre contrat, de choisir le réparateur professionnel auquel vous souhaitez
            recourir pour les réparations de votre véhicule.
            <br />
            <sup>(3)</sup> A l'exception de la franchise et de la vétusté
          </p>
        </div>
        <div className='col-12'>
          <OtherSection
            sectionName='service_auto'
            formName={form}
            choiceComponent={RadioField} // Or RadioField or InputField
            label='Souhaitez-vous bénéficier du service Auto Presto de Groupama ? *'
            options={OUI_NON}
            className='field field-radio'
            classNameGroup='field-radio-list field-radio-list-styled col-12'
            sectionClassName='grid'
            otherSections={{}}
          />
          <div className='label-small-italic'>
            Sous réserve de validation des garanties un réparateur Auto presto va vous contacter
            dans les meilleurs délais
          </div>
        </div>
        <div className='col-12'>
          <OtherSection
            sectionName='organisation_reparation'
            formName={form}
            choiceComponent={RadioField} // Or RadioField or InputField
            label='Souhaitez-vous organiser vous-même les réparations ? *'
            options={OUI_NON}
            sectionClassName='grid'
            classNameGroup='field-radio-list field-radio-list-styled col-12'
            className='field field-radio'
            otherSections={{
              true: () => (
                <div className='col-12'>
                  <div className='question-title'>
                    Coordonnées du garage qui effectuera les réparations :
                  </div>
                  <div className='question-container'>
                    <div className='grid'>
                      <div className='col-12'>
                        <Field
                          maxLength='2000'
                          name='name_reparation'
                          placeholder='...'
                          component={InputField}
                          label='Nom du garage *'
                          className='field'
                        />
                      </div>
                      <div className='col-12'>
                        <Field
                          maxLength='2000'
                          name='adresse_reparation'
                          placeholder='...'
                          component={InputField}
                          label='Adresse *'
                          className='field'
                        />
                      </div>
                      <div className='col-12'>
                        <Field
                          name='code_postal_reparation'
                          label='Code Postal / Commune *'
                          component={SelectField}
                          placeholder='Saisissez votre code postal ou ville'
                          type='number'
                          searchPromptText=''
                          loadingPlaceholder='Chargement...'
                          noResultsText='Aucun résultat trouvé'
                          className='field'
                          loadOptions={(input, callback) => {
                            if (!input || input.length < 2) return callback([]);
                            fetch(`${API_URL}/claims/postalcodetown/${input}`)
                              .then((response) => {
                                if (response.status >= 400) {
                                  return callback('Bad response from server');
                                }
                                return response.json();
                              })
                              .then((results) =>
                                callback(null, {
                                  options: map(results.data, (r) => ({
                                    value: `${r.codePostal}`,
                                    label: `${r.codePostal} - ${r.ville}`,
                                    ville: `${r.ville}`,
                                  })),
                                })
                              );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </div>
      <OtherSection
        sectionName='Commentary'
        formName={form}
        choiceComponent={TextareaField} // Or RadioField or InputField
        label='Commentaires'
        options={OUI_NON}
        className='field field-inline-comment'
        sectionClassName='col-12'
        otherSections={{}}
      />
      <div className='col-12'>
        <div className='question-title'>
          Insérer des pièces justificatives :
          <span className='label-small-italic'>
            {' '}
            Formats autorisés: jpg, png, gif, pdf. Taille Max : 3 Mo
          </span>
        </div>
        <div className='question-subtitle'> Par exemple : dépôt de plainte, déclaration, ...</div>
        <Field
          name='attachments_constat_2'
          buttonLabel='Déposer vos fichiers ici'
          component={FileField}
          multiple
          className='field field-dropzone'
          classNameGroup='field-radio-list field-radio-list-buttons col-12'
        />
      </div>
      <div className='btn-group between'>
        <button className='btn btn-primary' type='button' onClick={() => goToPrevious()}>
          <i className='far fa-angle-left' />
          <span>Précédent</span>
        </button>
        <button className='btn btn-primary' type='submit'>
          <span>Suivant</span>
          <i className='far fa-angle-right' />
        </button>
      </div>
    </div>
  </form>
);

AutomobilesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  visibleForm: PropTypes.number.isRequired,
  goToPrevious: PropTypes.func.isRequired,
  typeSinistre: PropTypes.string.isRequired,
};

export default reduxForm({
  form: 'automobilesForm',
  enableReinitialize: true,
  validate: (values = {}) => {
    const errors = {
      blesses: {
        tableau_blesses: [],
      },
    };

    errors.dommageConcerne = validate(values.dommageConcerne, {
      choice: {
        presence: { message: '^Merci de sélectionner un type de dommages' },
      },
    });

    if (values.dommageConcerne && values.dommageConcerne.choice === 'other') {
      errors.dommageConcerne = validate(values.dommageConcerne, {
        nomDommageConcerne: {
          presence: { message: '^Merci de préciser le dommage concerné' },
          length: { maximum: 255, tooLong: MESSAGE_ERRORS.max },
        },
      });
    } else if (values.dommageConcerne && values.dommageConcerne.choice !== 'other') {
      errors.dommageConcerne = validate(values.dommageConcerne, {
        CirconstancesDommages: {
          length: { maximum: 2000, tooLong: MESSAGE_ERRORS.max },
        },
      });
    }
    errors.conducteur_prenom = validate(values.conducteur_prenom, {
      choice: {
        presence: { message: '^Merci de renseigner le prénom' },
        length: { maximum: 50, tooLong: MESSAGE_ERRORS.max },
      },
    });
    errors.conducteur_nom = validate(values.conducteur_nom, {
      choice: {
        presence: { message: '^Merci de renseigner le nom' },
        length: { maximum: 50, tooLong: MESSAGE_ERRORS.max },
      },
    });
    errors.intervention_police = validate(values.intervention_police, {
      choice: {
        presence: { message: '^Merci de renseigner la présence des pompiers ou de la police' },
      },
    });
    errors.vehicule_implique_immatriculation = validate(values.vehicule_implique_immatriculation, {
      choice: {
        presence: { message: '^Merci de renseigner votre immatriculation' },
      },
    });
    errors.vehicule_implique_marque = validate(values.vehicule_implique_marque, {
      choice: {
        presence: { message: '^Merci de renseigner la marque du véhicule' },
      },
    });
    errors.vehicule_implique_modele = validate(values.vehicule_implique_modele, {
      choice: {
        presence: { message: '^Merci de renseigner le modèle du véhicule' },
      },
    });
    errors.Dommage_vehicule = validate(values.Dommage_vehicule, {
      choice: {
        presence: { message: '^Merci de renseigner si le véhicule a eu des dommages' },
      },
    });
    if (values.Dommage_vehicule && values.Dommage_vehicule.choice === 'true') {
      errors.Dommage_vehicule = validate(values.Dommage_vehicule, {
        description_dommage: {
          presence: { message: '^Merci de renseigner si le véhicule a eu des dommages' },
          length: { wrongLength: "^Votre identifiant n'est pas au bon format" },
        },
      });
    }
    errors.Constat_amiable = validate(values.Constat_amiable, {
      choice: {
        presence: { message: '^Merci de préciser si il y a eu un constat' },
      },
    });
    /*
    if (values.Constat_amiable && values.Constat_amiable.choice === 'true') {
      errors.Constat_amiable = validate(values.Constat_amiable, {
        attachments_constat: {
          presence: { message: '^Merci de déposer votre constat' },
        },
      });
    }
    */
    errors.depot_plainte = validate(values.depot_plainte, {
      choice: {
        presence: { message: '^Merci de préciser si il y a eu un dépôt de plainte' },
      },
    });
    errors.blesses = validate(values.blesses, {
      choice: {
        presence: { message: '^Merci de préciser si il y a eu un dépôt de plainte' },
      },
    });
    errors.presence_tiers = validate(values.presence_tiers, {
      choice: {
        presence: { message: '^Merci de préciser si il y a eu un tiers' },
      },
    });

    if (values.presence_tiers && values.presence_tiers.choice === 'true') {
      errors.presence_tiers = validate(values.presence_tiers, {
        presence_tiers_identite: {
          presence: { message: "^Merci de renseigner l'identité et l'adresse du tiers" },
          length: { wrongLength: "^Votre identifiant n'est pas au bon format" },
        },
        presence_tiers_immatriculation: {
          presence: { message: "^Merci de renseigner l'immatriculation" },
          length: { wrongLength: "^Votre identifiant n'est pas au bon format" },
        },
        presence_tiers_identite_assureur: {
          presence: { message: '^Merci de renseigner les informations de son assureur' },
          length: { wrongLength: "^Votre identifiant n'est pas au bon format" },
        },
      });
    }
    errors.reparation_vehicule = validate(values.reparation_vehicule, {
      choice: {
        presence: { message: '^Merci de préciser' },
      },
    });
    errors.vehicule_encore_rouler = validate(values.vehicule_encore_rouler, {
      choice: {
        presence: { message: '^Merci de préciser si votre véhicule peut rouler' },
      },
    });
    errors.localisation_vehicule = validate(values.localisation_vehicule, {
      choice: {
        presence: { message: '^Merci de préciser le lieu de votre véhicule' },
      },
    });
    /*
    if (values.localisation_vehicule && values.localisation_vehicule.choice === 'Autres') {
      errors.localisation_vehicule = validate(values.localisation_vehicule, {
        adresse_autres: {
          presence: { message: '^Merci de préciser le lieu de votre véhicule' },
        },
        code_postal_city_autres: {
          presence: { message: '^Merci de préciser le lieu de votre véhicule' },
        },
      });
    }
    if (values.localisation_vehicule && values.localisation_vehicule.choice === 'Garagiste') {
      errors.localisation_vehicule = validate(values.localisation_vehicule, {
        adresse_garagiste: {
          presence: { message: '^Merci de préciser le lieu de votre véhicule' },
        },
        code_postal_city_garagiste: {
          presence: { message: '^Merci de préciser le lieu de votre véhicule' },
        },
      });
    }
*/
    errors.circonstances_evenement = validate.single(values.circonstances_evenement, {
      presence: { message: "^Merci de décrire l'événement" },
      length: { maximum: 2000, tooLong: MESSAGE_ERRORS.max },
    });
    errors.description_dommage = validate.single(values.description_dommage, {
      presence: { message: "^Merci de décrire l'événement" },
      length: { maximum: 2000, tooLong: MESSAGE_ERRORS.max },
    });

    errors.batiment_concernes = validate.single(values.batiment_concernes, {
      presence: {
        message:
          '^Merci de préciser le(s) bâtiment(s) concerné(s) et la description succincte des dommages',
      },
    });
    errors.oui_non_contenu_endommage = validate(values.oui_non_contenu_endommage, {
      choice: {
        presence: { message: '^Merci de préciser si des contenus sont concernés par le sinistre' },
      },
    });

    errors.service_auto = validate(values.service_auto, {
      choice: {
        presence: { message: '^Merci de préciser votre choix' },
      },
    });
    errors.organisation_reparation = validate(values.organisation_reparation, {
      choice: {
        presence: { message: '^Merci de préciser votre choix' },
      },
    });
    if (values.organisation_reparation && values.organisation_reparation.choice === 'true') {
      errors.organisation_reparation = validate(values.organisation_reparation, {
        name_reparation: {
          presence: { message: '^Merci de préciser le nom du garage' },
          length: { wrongLength: "^Votre identifiant n'est pas au bon format" },
        },
        adresse_reparation: {
          presence: { message: "^Merci de préciser l'adresse du garage" },
          length: { wrongLength: "^Votre identifiant n'est pas au bon format" },
        },
        code_postal_reparation: {
          presence: { message: '^Merci de préciser le code postal et la ville du garage' },
        },
      });
    }

    //---------------------- Constat vérification < 2 MO ---------------------------
    if (!values.attachments_constat) {
      return errors;
    }
    if (values.attachments_constat.length < 0) {
      errors.attachments_constat = 'Merci de déposer votre constat';
      return errors;
    }
    return errors;
    /*if (values.oui_non_contenu_endommage && values.oui_non_contenu_endommage.choice === 'true') {
      errors.oui_non_contenu_endommage = {
        ...errors.oui_non_contenu_endommage,
        tableau_dommage: map(
          values.oui_non_contenu_endommage.tableau_dommage,
          values => ContenuEndommageFields.validate(values)
        ),
      }
    }
    return errors;*/
  },
})(AutomobilesForm);
