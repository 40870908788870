import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import mime from 'mime-types';
import { map, filter } from 'lodash';

import Chromestore from '../../utils/chromestore';
import { resizeImage } from '../../utils/FileUtils';

import { API_URL, AUTHORIZED_UPLOAD_FILES, AUTHORIZED_UPLOAD_IMAGES, RESIZE_IMAGE_MAX_SIZE } from '../../constants/Config';

import { uploadFiles, removeFile } from '../../store/modules/uploads';

import FieldError from './FieldError';
import IconButton from './icon/IconButton';

@connect(
  null,
  { uploadFiles, removeFile },
)

export default class FileField extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    labelItalic: PropTypes.string,
    buttonLabel: PropTypes.string,
    fontIcon: PropTypes.string,
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    }),
    uploadFiles: PropTypes.func.isRequired,
    removeFile: PropTypes.func.isRequired,
    acceptedFiles: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    meta: PropTypes.shape(),
    basePath: PropTypes.string,
    baseName: PropTypes.string,
    saveBase64: PropTypes.bool,
    hideFileList: PropTypes.bool,
  };

  constructor () {
    super();
    this.state = {
      errorMessage: '',
    };
  }

  onDropRejected (file) {
    if (file[0].size > 3145728) {
      this.setState({ errorMessage: 'La taille du fichier ne peut excéder 3 Mo' });
    } else {
      this.setState({ errorMessage: 'Merci de vérifier le format du fichier' });
    }
  }

  uploadFiles (files) {
    this.setState({ errorMessage: '' });
    if (!files || files.length === 0) {
      return;
    }

    if (this.props.saveBase64 === true) {
      const readFiles = [];
      files.map((file, index) => readFiles.push(new Promise((resolve) => {
        const filename = `${moment().format('YYYYMMDD-HHmmss')}-${this.props.baseName}_${index}_${file.name}`;
        return resizeImage(file, RESIZE_IMAGE_MAX_SIZE, filename, file.type)
        .then((resizedImage) => {
          Chromestore.getDir(this.props.basePath || '', { create: true }, () => {
            Chromestore.write(
              `${this.props.basePath || ''}/${filename}`,
              resizedImage.type,
              resizedImage,
              { create: true },
              fileEntry => resolve({
                filename: `${this.props.basePath || ''}/${filename}`,
                url: fileEntry.toURL(),
              }),
            );
          });
        });
      })));
      return Promise.all(readFiles)
      .then(base64Files =>
        this.props.input.onChange(!this.props.multiple ? base64Files[0] : base64Files.concat(this.props.input.value || [])));
    }
    const filesToRemove = this.props.input.value;
    return this.props.uploadFiles(files)
    .then(({ response }) => {
      this.props.input.onChange(!this.props.multiple ? response[0] : Object.values(response).concat(this.props.input.value || []));
      if (!this.props.multiple && filesToRemove) {
        this.props.removeFile(filesToRemove);
      }
    });
  }

  deleteFile (element) {
    const removeValue = () => {
      if (this.props.multiple) {
        return this.props.input.onChange(filter(this.props.input.value, v => v !== element));
      }
      return this.props.input.onChange(null);
    };
    if (this.props.saveBase64 === true) {
      Chromestore.deleteFile(element.filename);
      return removeValue();
    }
    return this.props.removeFile(element)
    .finally(() => removeValue());
  }

  renderFileIcon (value) {
    if (AUTHORIZED_UPLOAD_IMAGES.includes(mime.lookup(value))) {
      return (<img src={`${API_URL}/uploads/${value}`} />);
    }
    return (<i className="fas fa-file-pdf icon-type-file"/>);
  }

  renderImageBlock (value, index) {
    return !value ? null : (
      <div className="img-block" key={index}>
        <a
          href={this.props.saveBase64 ? null : `${API_URL}/uploads/${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
        </a>
        {this.renderFileIcon(value)}

        {!this.props.disabled && (
          <div className="infobulle2">
            <div className="infobulle-icon">
              <span className="btn-img" onClick={() => this.deleteFile(value)}>
                <i className="fas fa-times" />
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }

  render () {
    const {
      className,
      id,
      label,
      labelItalic,
      buttonLabel,
      fontIcon,
      disabled,
      meta,
      multiple,
      input,
      acceptedFiles,
      hideFileList,
    } = this.props;
    const { errorMessage } = this.state;

    return (
      <div className={classNames(className, { 'read-only': disabled })}>
        <FieldError {...meta} errorMessage={errorMessage} />
        <div className="dropzone-block">
          {
            !hideFileList && (
              input.value && multiple
                ? map(input.value, (v, index) => this.renderImageBlock(v, index))
                : this.renderImageBlock(input.value)
            )
          }
          {!disabled && (multiple || !input.value || input.value.length === 0) && (
            <Dropzone
              id={id || 'file-input'}
              className="dropzone"
              onDrop={files => this.uploadFiles(files)}
              multiple={multiple === true}
              accept={acceptedFiles ? acceptedFiles.join(',') : AUTHORIZED_UPLOAD_FILES.concat(AUTHORIZED_UPLOAD_IMAGES).join(',')}
              onDropRejected={(res) => this.onDropRejected(res)}
              maxSize={3 * 1024 * 1024}
            >
              <i className="fas fa-upload" />
              <span />
              <button type="button">{buttonLabel}</button>
            </Dropzone>
          )}
          <div className="field-error">{errorMessage}</div>
        </div>
        {label &&
          <label htmlFor={id || 'file-input'}>
            {fontIcon &&
              <i className={classNames(fontIcon)} />
            }
            {label}
            <span className="label-italic">{labelItalic}</span>
          </label>
        }
      </div>
    );
  }
}

// batch permettant la suppression d'un fichier au bout de "10 jours"

/*
@echo off
setlocal enabledelayedexpansion

set "Year=%DATE:~6,4%"
set "Month=%DATE:~3,2%"
set "Day=%DATE:~0,2%"

:: Création d'un timestamp de référence (la date courante moins le nombre de jour voulue).
set /a "TimeStamp=(((Year * 365) + ((Month - 1) * 30)) + Day) - 10"

:: Le répertoire "%cd%\test" est parcouru à la recherche de tous les fichiers
:: La date de création du fichier est récupèré
:: La date de création du fichier est parsé
:: Un timestamp de la date de création du fichier est créé
:: Si le timestamp de la date de création du fichier est inférieur au timestamp  de référence, le fichier est supprimé.
for /r "%cd%\test" %%a in (*) do (
  for /f "tokens=1" %%b in ("%%~ta") do (
    for /f "tokens=1-3 delims=/" %%c in ("%%b") do (
      set /a "FileTimeStamp=(((%%e * 365) + ((%%d - 1) * 30)) + %%c)"
      if !FileTimeStamp! LSS %TimeStamp% del /q "%%~dpnxa"
    )
  )
)

pause
*/
