import { formsActionsHandlers } from './forms';
// import { accountsActionsHandlers } from './accounts';
// import { optionsActionsHandlers } from './options';
import initialState from '../initialState';
import caisseActionsHandlers from './caisse';


// import { uploadsActionsHandlers } from './uploads';
import { globalsActionsHandlers } from './globals';

const actionsHandlers = {
  ...formsActionsHandlers,
  // ...accountsActionsHandlers,
  // ...optionsActionsHandlers,
  // ...uploadsActionsHandlers,
  ...caisseActionsHandlers,
  ...globalsActionsHandlers,
};

export default function reducer (state = initialState, action) {
  const reduceFn = actionsHandlers[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
