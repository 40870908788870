import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, FormSection } from 'redux-form';
import validate from 'validate.js';
import InputField from '../elements/InputField';
import OtherSection from '../elements/OtherSection';
import RadioField from '../elements/RadioField';
import TextareaField from '../elements/TextareaField';
import MultiCheckboxField from '../elements/MultiCheckboxField';
import TiersIdentifieFields from './TiersIdentifieFields';
import { MESSAGE_ERRORS, OUI_NON, CORPOREL_MATERIEL, SINISTRE_CONCERNE } from '../../constants/Config';
import { formPosition } from '../../store/modules/forms';

const Rcpj = ({
  handleSubmit,
  form,
  reset,
  visibleForm,
  goToPrevious,
  typeSinistre,
}) => (
  <form
    className={visibleForm === formPosition.indexOf('dommagesForm') ? 'grid' : 'hidden'}
    onSubmit={handleSubmit}
  >
    <div className="col-12">
      <h3>
        Circonstances de votre sinistre { SINISTRE_CONCERNE[typeSinistre] }
      </h3>
    </div>

    { typeSinistre === 'Responsabilite_civile'
      && (
        <div className="col-12">
          <Field
            name="type_dommage_provoque"
            id="type_dommage_provoque"
            component={MultiCheckboxField}
            className="field field-radio"
            classNameGroup="field-radio-list field-radio-list-buttons col-12"
            label="Quelle est la nature du dommage provoqué ? *"
            options={CORPOREL_MATERIEL}
          />
        </div>
      )}
    <div className="col-12">
      {/* dommage provoqué */}
      <Field
        maxLength="2000"
        name="circonstances_evenement"
        placeholder="Détaillez les circonstances de l'événement "
        component={TextareaField}
        label="Circonstances détaillées de l'événement *"
        className="field field-textarea col-12"
      />
    </div>

    <div className="col-12">
      {/* dommage subi */}
      <OtherSection
        sectionName="oui_non_subi_dommage"
        formName={form}
        choiceComponent={RadioField} // Or RadioField or InputField
        label="Avez-vous subi des dommages ? *"
        options={OUI_NON}
        className="field field-radio"
        classNameGroup="field-radio-list field-radio-list-styled col-12"
        sectionClassName="grid"
        otherSections={{
          true: () => (
            <div className="grid col-11" data-push-left="off-1">
              { typeSinistre === 'Responsabilite_civile'
              && (
                <Field
                  name="type_dommage_subis"
                  id="type_dommage_subis"
                  component={MultiCheckboxField}
                  className="field field-radio"
                  classNameGroup="field-radio-list field-radio-list-buttons col-12"
                  label="Quelle est la nature du dommage subi ? *"
                  options={CORPOREL_MATERIEL}
                />)
              }
              <Field
                maxLength="2000"
                name="description_des_dommages_subi"
                placeholder="Détaillez les circonstances de l'événement"
                component={TextareaField}
                label="Circonstances détaillées de l'événement *"
                className="field field-textarea col-12"
              />
            </div>
          ),
        }}
      />
    </div>

    <div className="col-12">
      {/* tiers identifie */}
      <OtherSection
        sectionName="oui_non_tiers_identifie"
        formName={form}
        choiceComponent={RadioField} // Or RadioField or InputField
        label="Y a-t-il un tiers d'identifié ? *"
        options={OUI_NON}
        className="field field-radio"
        classNameGroup="field-radio-list field-radio-list-styled col-12"
        sectionClassName="grid"
        otherSections={{
          true: () => (
            <TiersIdentifieFields sectionName="tiersIdentifie" />
          ),
        }}
      />
    </div>
    <div className="col-12">
      {/* Vehicule identifie */}
      <OtherSection
        sectionName="oui_non_vehicule_dentifie"
        formName={form}
        choiceComponent={RadioField} // Or RadioField or InputField
        label="Y a-t-il un véhicule concerné ? *"
        options={OUI_NON}
        className="field field-radio"
        classNameGroup="field-radio-list field-radio-list-styled col-12"
        sectionClassName="grid"
        otherSections={{
          true: () => (
            <div className="grid col-11" data-push-left="off-1">
              <Field
                maxLength="50"
                name="marque_vehicule"
                component={InputField}
                label="Marque du véhicule"
                className="field col-12"
              />
              <Field
                maxLength="10"
                name="immatriculation_vehicule"
                component={InputField}
                label="Immatriculation du véhicule"
                className="field col-12"
              />
              <Field
                maxLength="2000"
                name="identite_adresse_assureur_vehicule"
                component={TextareaField}
                type="text"
                label="Son assureur (Identité, adresse, N° contrat, N° de sinistre...) *"
                placeholder="Son assureur (Identité, adresse, N° contrat, N° de sinistre...)"
                className="field field-textarea col-12"
              />
            </div>
          ),
        }}
      />
    </div>
    <div className="col-12">
      {/* Observations */}
      <Field
        maxLength="2000"
        name="Observations"
        component={TextareaField}
        type="text"
        label="Observations"
        className="field field-textarea"
      />
    </div>
    <div className="btn-group between">
      <button className="btn btn-primary" type="button" onClick={() => goToPrevious()}>
        <i className="far fa-angle-left" />
        <span>Précédent</span>
      </button>
      <button className="btn btn-primary" type="submit">
        <span>Suivant</span>
        <i className="far fa-angle-right" />
      </button>
    </div>
  </form>
);

Rcpj.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  typeSinistre: PropTypes.string.isRequired,
  visibleForm: PropTypes.string.isRequired,
  goToPrevious: PropTypes.func.isRequired,
};

export default connect(
  state => {
    console.log('on est dans le state initiailsmqjdklm jqsdkljdslkmjsdklqmjqsklmqlm', state);
    return ({
      initialValues: {
        circonstances_evenement: '',
        oui_non_subi_dommage: {},
        oui_non_tiers_identifie: {},
        oui_non_vehicule_dentifie: {},
      },
    })},
)(reduxForm({
  form: 'rcpj',
  enableReinitialize: true,

  validate: (values = {}) => {
    const errors = {};

    errors.type_dommage_provoque = validate.single(values.type_dommage_provoque, {
      presence: { message: '^Merci de préciser la nature du dommage provoqué' },
    });

    errors.circonstances_evenement = validate.single(values.circonstances_evenement, {
      presence: { message: '^Merci de préciser les circonstances detaillées de l\'événement' },
    });

    errors.oui_non_subi_dommage = validate(values.oui_non_subi_dommage, {
      choice: {
        presence: { message: '^Merci de préciser si vous avez subi des dommages' },
      },
    });
    if (values.oui_non_subi_dommage && values.oui_non_subi_dommage.choice === 'true') {
      errors.oui_non_subi_dommage = validate(values.oui_non_subi_dommage, {
        description_des_dommages_subi: {
          presence: { message: '^Merci de préciser les circonstances detaillées de l\'événement' },
          length: { maximum: 2000, tooLong: MESSAGE_ERRORS.max },
        },
        type_dommage_subis: {
          presence: { message: '^Merci de préciser la nature du dommage subi' },
        },
      });
    }

    errors.oui_non_provoque_dommage = validate(values.oui_non_provoque_dommage, {
      choice: {
        presence: { message: '^Merci de préciser si vous avez provoqué des dommages' },
      },
    });
    if (values.oui_non_provoque_dommage && values.oui_non_provoque_dommage.choice === 'true') {
      errors.oui_non_provoque_dommage = validate(values.oui_non_provoque_dommage, {
        description_des_dommages_provoque: {
          presence: { message: '^Merci de décrire les dommages provoqués' },
          length: { maximum: 2000, tooLong: MESSAGE_ERRORS.max },
        },
      });
    }

    errors.oui_non_tiers_identifie = validate(values.oui_non_tiers_identifie, {
      choice: {
        presence: { message: '^Merci de préciser si un tiers est identifié' },
      },
    });
    if (values.oui_non_tiers_identifie && values.oui_non_tiers_identifie.choice === 'true') {
      errors.oui_non_tiers_identifie = TiersIdentifieFields.validate(values.oui_non_tiers_identifie, 'tiersIdentifie');
    }

    errors.oui_non_vehicule_dentifie = validate(values.oui_non_vehicule_dentifie, {
      choice: {
        presence: { message: '^Merci de préciser si un véhicule est concerné' },
      },
    });

    if (values.oui_non_vehicule_dentifie && values.oui_non_vehicule_dentifie.choice === 'true') {
      errors.oui_non_vehicule_dentifie = validate(values.oui_non_vehicule_dentifie, {
        marque_vehicule: {
          length: { maximum: 50, tooLong: MESSAGE_ERRORS.max },
        },
        immatriculation_vehicule: {
          length: { maximum: 10, tooLong: MESSAGE_ERRORS.max },
        },
        identite_adresse_assureur_vehicule: {
          presence: { message: "^Merci de préciser l'identité et l'adresse de son assureur" },
          length: { maximum: 2000, tooLong: MESSAGE_ERRORS.max },
        },
        degat_prejudice_subis_vehicule: {
          presence: { message: '^Merci de préciser les dégats ou préjudice subis' },
          length: { maximum: 2000, tooLong: MESSAGE_ERRORS.max },
        },
      });
    }

    errors.Observations = validate.single(values.Observations, {
      length: { maximum: 2000, tooLong: MESSAGE_ERRORS.max },
    });

    return errors;
  },
})(Rcpj));
