import React from 'react';
import PropTypes from 'prop-types';
import { get, map } from 'lodash';
import validate from 'validate.js';
import fetch from 'isomorphic-fetch';
import { Field, FormSection } from 'redux-form';

import InputField from '../elements/InputField';
import SelectField from '../elements/SelectField';
import { API_URL, MESSAGE_ERRORS } from '../../constants/Config';

const AddressPostalTown = ({
  sectionName, labelAdress, labelCpVille,
}) => (
  <FormSection className="grid col-12" name={sectionName}>
    <div className="col-12">
      <Field
        name="address"
        component={InputField}
        type="text"
        label={labelAdress || 'Adresse *'}
        maxLength="255"
        className="field"
      />
    </div>
    <div className="col-12">
      <Field
        name="code_postal_city"
        component={SelectField}
        placeholder="Saisissez votre code postal ou ville"
        type="number"
        label={labelCpVille || 'Code Postal / Ville *'}
        searchPromptText=""
        loadingPlaceholder="Chargement..."
        noResultsText="Aucun résultat trouvé"
        className="field"
        loadOptions={(input, callback) => {
          if (!input || input.length < 2) return callback([]);
          fetch(`${API_URL}/claims/postalcodetown/${input}`)
          .then((response) => {
            if (response.status >= 400) {
              return callback('Bad response from server');
            }
            return response.json();
          })
          .then(results => callback(null, {
            options: map(results.data, r => ({
              value: `${r.codePostal}`,
              label: `${r.codePostal} - ${r.ville}`,
              ville: `${r.ville}`,
            })),
          }));
        }}
      />
    </div>
  </FormSection>
);

AddressPostalTown.propTypes = {
  sectionName: PropTypes.string.isRequired,
  labelAdress: PropTypes.string.isRequired,
  labelCpVille: PropTypes.string,
};

const validateCPCity = (postalCode, city) => {
  let errors = [];
  errors = errors.concat(validate.single(postalCode, {
    presence: { message: '^Merci de préciser le code postal' },
    length: { minimum: 3, tooShort: MESSAGE_ERRORS.min },
  }) || []);
  errors = errors.concat(validate.single(city, {
    presence: { message: '^merci de préciser la ville' },
  }) || []);
  return errors && errors.length > 0 ? errors : undefined;
};

AddressPostalTown.validate = (values = {}, sectionName) => {
  const errors = {
    [sectionName]: {
      address: validate.single(get(values, `${sectionName}.address`), {
        presence: { message: "^Merci de préciser l'adresse" },
        length: { maximum: 255, tooLong: MESSAGE_ERRORS.max },
      }),
      code_postal_city: validateCPCity(
        get(values, `${sectionName}.code_postal_city.value`),
        get(values, `${sectionName}.code_postal_city.ville`),
      ),
    },
  };
  return errors;
};

export default (AddressPostalTown);
