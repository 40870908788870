
export function getHash () {
  return window.location.hash.substr(1);
}

export function getSearch () {
  return window.location.search.substr(1);
}

export function getJSONFromUrlParams (fromSearch = false) {
  const hash = fromSearch === true ? getSearch() : getHash();
  const query = hash.substr(hash.indexOf('?') + 1);
  const result = {};
  query.split('&').forEach((part) => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result || {};
}
