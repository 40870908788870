import React from "react";
import PropTypes from "prop-types";
import {
  reduxForm,
  Field,
  formValueSelector,
  getFormValues,
  reset,
} from "redux-form";
import { connect } from "react-redux";
import validate from "validate.js";
import moment from "moment";
import InputField from "../elements/InputField";
import OtherSection from "../elements/OtherSection";
import CleaveField from "../elements/CleaveField";
import RadioField from "../elements/RadioField";
import DateField from "../elements/DateField";
import FileField from "../elements/FileField";
import { formPosition } from "../../store/modules/forms";
import AddressPostalTownFields from "./AddressPostalTownFields";
import {
  MESSAGE_ERRORS,
  OUI_NON,
  SINISTRE_CONCERNE,
  SINISTRE_CONCERNE_2,
  CONTRAT_MISSION,
} from "../../constants/Config";

const sinistreConcerneValidate = (typeSinistreValue) =>
  typeSinistreValue ? undefined : "error";

const SinistreForm = ({
  handleSubmit,
  form,
  visibleForm,
  goToPrevious,
  typeSinistre,
  onSubmit,
  sinistreConcerne,
  isAuto,
}) => {
  return (
    <form
      className={
        visibleForm === formPosition.indexOf("sinistreForm") ? "grid" : "hidden"
      }
      onSubmit={handleSubmit}
    >
      <div className="col-12">
        <div className="grid col-12">
          <div className="col-6_xs-6">
            {/* Date */}
            <Field
              name="date"
              component={DateField}
              label="Date *"
              placeholder="Date"
              className="field field-full"
            />
          </div>
          <div className="col-6_xs-6">
            {/* heure */}
            <Field
              name="heureSinistre"
              component={CleaveField}
              type="text"
              placeholder="hh:mm"
              label="Heure"
              className="field field-full"
              cleaveOptions={{
                time: true,
                timePattern: ["h", "m"],
                delimiter: ":",
              }}
            />
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="grid col-12">
          <div className="col-12">
            <Field
              name="nom_etab_sinistrer"
              component={InputField}
              type="text"
              label="Nom de l'établissement sinistré&nbsp;*"
              className="field"
              maxLength="255"
            />
          </div>
        </div>
        <AddressPostalTownFields
          sectionName="localisation_sinistre"
          labelAdress="Adresse du sinistre *"
        />
      </div>

      <div className="col-12">
        <OtherSection
          sectionName="oui_non_plainte"
          formName={form}
          choiceComponent={RadioField} // Or RadioField or InputField
          label="Y a-t-il eu un dépôt de plainte ? *"
          options={OUI_NON}
          className="field field-radio"
          classNameGroup="field-radio-list field-radio-list-styled col-12"
          sectionClassName="grid col-12"
          otherSections={{}}
        />
      </div>

      {isAuto === true ? (
        <div className="col-12">
          <OtherSection
            sectionName="sinistre_concerne"
            formName={form}
            choiceComponent={RadioField} // Or RadioField or InputField
            label="Votre sinistre concerne ? *"
            options={SINISTRE_CONCERNE}
            className="field field-radio"
            classNameGroup="field-radio-list field-radio-list-buttons col-12"
            sectionClassName="grid col-12"
            otherSections={{
              Automobile: () => (
                <div className="col-12">
                  <Field
                    sectionName="contrat_concerne"
                    formName={form}
                    label="Vous avez un contrat ? *"
                    name="Contrat"
                    options={CONTRAT_MISSION}
                    component={RadioField}
                    type="string"
                    className="field field-radio"
                    classNameGroup="field-radio-list field-radio-list-buttons col-12"
                    sectionClassName="grid col-12"
                  />
                </div>
              ),
            }}
          />
        </div>
      ) : (
        <div className="col-12">
          <OtherSection
            sectionName="sinistre_concerne"
            formName={form}
            choiceComponent={RadioField} // Or RadioField or InputField
            label="Votre sinistre concerne ? *"
            options={SINISTRE_CONCERNE_2}
            className="field field-radio"
            classNameGroup="field-radio-list field-radio-list-buttons col-12"
            sectionClassName="grid col-12"
            otherSections={{}}
          />
        </div>
      )}

      {sinistreConcerne && sinistreConcerne.choice !== "Automobile" ? (
        <div className="col-12">
          <Field
            name="attachments"
            label="Insérer des pièces justificatives:"
            labelItalic="Formats autorisés: jpg, png, gif, pdf. Taille Max: 03 Mo"
            buttonLabel="Déposer vos fichiers ici"
            component={FileField}
            multiple
            className="field field-radio-list-buttons"
            classNameGroup="field-radio-list field-radio-list-buttons col-12"
          />
        </div>
      ) : null}
      <div className="btn-group between">
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => goToPrevious()}
        >
          <i className="far fa-angle-left" />
          <span>Précédent</span>
        </button>
        <button className="btn btn-primary" type="submit">
          <span>Suivant</span>
          <i className="far fa-angle-right" />
        </button>
      </div>
    </form>
  );
};

SinistreForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  visibleForm: PropTypes.number.isRequired,
  goToPrevious: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sinistreConcerne: PropTypes.shape(),
  isAuto: PropTypes.bool,
};

const selector = formValueSelector("sinistreForm");

const SinistreFormConnected = connect((state) => {
  console.log("value of state inSinistreForm: ", state);
  console.log(
    "value of state SINISTRE_CONCERNE ",
    selector(state, "sinistre_concerne.choice")
  );
  return {
    initialValues: state.data.entities.forms.sinistreForm || {}, // pull initial values from account reducer
    sinistreConcerne: selector(state, "sinistre_concerne"),
    isAuto:
      (state.data.entities.caisse && state.data.entities.caisse.AUTO) || false,
  };
})(
  reduxForm({
    form: "sinistreForm",
    enableReinitialize: true,
    validate: (values = {}) => {
      const errors = {
        ...AddressPostalTownFields.validate(values, "localisation_sinistre"),
        oui_non_plainte: validate(values.oui_non_plainte, {
          choice: {
            presence: {
              message:
                "^Merci de préciser si un dépôt de plainte a été effectué",
            },
          },
        }),
        sinistre_concerne: validate(values.sinistre_concerne, {
          choice: {
            presence: { message: "^Merci de préciser le type de sinistre " },
          },
        }),
      };
      if(!values.nom_etab_sinistrer){
        errors.nom_etab_sinistrer = "Merci de préciser le nom de l'établissement sinistré";
      }

      if (
        values.sinistre_concerne &&
        values.sinistre_concerne.choice === "Automobile"
      ) {
        errors.sinistre_concerne = validate(values.sinistre_concerne, {
          Contrat: {
            presence: { message: "^Merci de sélectionner un type de contrat" },
          },
        });
      }

      const date = moment(values.date, "DD/MM/YYYY");
      if (moment().isBefore(date))
        errors.date =
          "La date du sinistre doit être antérieure à la date du jour";
      if (!values.date) errors.date = "Merci de préciser la date du sinistre";
      if (!values.attachments) {
        return errors;
      }

      if (values.attachments.length > 5) {
        errors.attachments = "Maximum 5 fichiers autorisés";
        return errors;
      }
      return errors;
    },
  })(SinistreForm)
);

export default SinistreFormConnected;
