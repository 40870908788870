import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FieldError from './FieldError';

const InputField = ({
  input, className, id, label, fontIcon, placeholder, type, disabled, autoFocus, meta, maxLength
}) => (
  <div className={classNames(className, { 'read-only': disabled })}>
    <FieldError {...meta} />
    <input
      {...input}
      id={id || (input && input.name) || 'input'}
      type={type || 'text'}
      placeholder={placeholder}
      disabled={disabled ? 'disabled' : ''}
      autoComplete="off"
      autoFocus={autoFocus || false}
      autoCapitalize="none"
      maxLength={maxLength}
    />
    {label &&
      <label htmlFor={id || (input && input.name) || 'input'}>
        {fontIcon &&
          <i className={classNames(fontIcon)} />
        }
        {label}
      </label>
    }
  </div>
);

InputField.propTypes = {
  input: PropTypes.shape(),
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  fontIcon: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape(),
};

export default InputField;
