import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map } from 'lodash';

import FieldError from './FieldError';

const mapObjectToOptions = objectOptions => map(
  objectOptions, (label, value) => ({ value, label }),
);

const RadioField = ({
  input, classNameGroup, className, label, fontIcon, disabled, autoFocus, meta,
  options, infobulle,
}) => {
  if (!options) {
    console.error('Empty options for select with label:', label);
  } else if (Object.prototype.toString.call(options) === '[object Object]') {
    options = mapObjectToOptions(options);
  }

  return (
    <div className={classNames(classNameGroup, { 'read-only': disabled })}>
      <FieldError {...meta} />
      <div className="field-radio-options">
        {options.map(option => (
          <div className={className} key={option.value}>
            <input
              {...input}
              id={`radio_${input.name}_${option.value}`}
              type="radio"
              value={option.value}
              checked={input.value === option.value}
              disabled={disabled ? 'disabled' : ''}
              autoFocus={autoFocus || false}
            />
            <label className="label-option" htmlFor={`radio_${input.name}_${option.value}`}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
      {label &&
        <label>
          {fontIcon &&
            <i className={classNames(fontIcon)} />
          }
          {label}
        </label>
      }

      {infobulle
        && (
          <div className="infobulle">
            <div className="infobulle-icon">
              <i className="fas fa-info" />
            </div>
            {infobulle}
          </div>
        )
      }
    </div>
  );
};

RadioField.propTypes = {
  input: PropTypes.shape(),
  className: PropTypes.string,
  infobulle: PropTypes.shape(),
  id: PropTypes.string,
  classNameGroup: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
        PropTypes.shape().isRequired,
      ]),
      label: PropTypes.string.isRequired,
    })).isRequired,
    PropTypes.shape().isRequired,
  ]).isRequired,
  meta: PropTypes.shape(),
};

export default RadioField;
