import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { map } from 'lodash';

import { isUserAgentMobile } from '../../utils/DeviceUtils';

import FieldError from './FieldError';

const mapObjectToOptions = objectOptions => map(objectOptions, (label, value) => ({ value, label }));

const SelectField = ({
  input,
  className,
  id,
  label,
  placeholder,
  searchPromptText,
  loadingPlaceholder,
  noResultsText,
  disabled,
  options,
  loadOptions,
  multi,
  creatable,
  meta,
  autoFocus,
}) => {
  if (!options && !loadOptions) {
    console.error('Empty options for select with label:', label);
  } else if (Object.prototype.toString.call(options) === '[object Object]') {
    options = mapObjectToOptions(options);
  }
  let SelectCompnent = Select;
  if (creatable) {
    SelectCompnent = Select.Creatable;
  } else if (!options && loadOptions) {
    SelectCompnent = Select.Async;
  }
  if (!placeholder) {
    placeholder = creatable ? 'Créer / Sélectionner...' : 'Sélectionner...';
  }
  return (
    <div className={classNames(className, { 'read-only': disabled })}>
      <FieldError {...meta} />
      {!multi && !loadOptions && !creatable && isUserAgentMobile ? (
        <select
          id={id || (input && input.name) || 'select'}
          value={input.value || undefined}
          onChange={e => input.onChange(e && e.target ? e.target.value : null)}
          placeholder={placeholder}
          searchPromptText={searchPromptText}
          loadingPlaceholder={loadingPlaceholder}
          noResultsText={noResultsText}
          disabled={disabled}
          loadOptions={loadOptions}
          multi={multi || false}
        >
          <option value="" disabled selected hidden />
          {options && options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      ) : (
        <SelectCompnent
          id={id || (input && input.name) || 'select'}
          value={input.value}
          onChange={(option) => {
            if (multi === true) {
              return input.onChange(map(option, 'value'));
            }
            if (loadOptions) {
              return input.onChange(option);
            }
            return input.onChange(option ? option.value : null);
          }}
          placeholder={placeholder}
          searchPromptText={searchPromptText}
          loadingPlaceholder={loadingPlaceholder}
          noResultsText={noResultsText}
          disabled={disabled}
          autoFocus={autoFocus || false}
          options={options}
          loadOptions={loadOptions}
          multi={multi || false}
        />
      )}
      {label &&
        <label htmlFor={id || (input && input.name) || 'select'}>{label}</label>
      }
    </div>
  );
};

SelectField.propTypes = {
  input: PropTypes.shape(),
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  searchPromptText: PropTypes.string,
  loadingPlaceholder: PropTypes.string,
  noResultsText: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.shape({
      _tag: PropTypes.string.isRequired,
    }),
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
        PropTypes.shape().isRequired,
      ]),
      label: PropTypes.string.isRequired,
    })).isRequired,
    PropTypes.shape().isRequired,
  ]),
  loadOptions: PropTypes.func,
  multi: PropTypes.bool,
  creatable: PropTypes.bool,
  meta: PropTypes.shape(),
};

export default SelectField;
