import mimeTypes from 'mimetypes';

export function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}

export function dataURLToBlob (dataURL, filename) {
  const BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    const parts = dataURL.split(',');
    const contentType = parts[0].split(':')[1];
    const raw = parts[1];

    return new File([raw], `${filename}.${mimeTypes.detectExtension(contentType)}`, { type: contentType });
  }

  const parts = dataURL.split(BASE64_MARKER);
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;

  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new File([uInt8Array], `${filename}.${mimeTypes.detectExtension(contentType)}`, { type: contentType });
}

export function resizeImage (file, maxSize, filename, contentType = 'image/jpeg') {
  return new Promise((resolve, reject) => {
    // Ensure it's an image
    if (!file.type.match(/image.*/)) {
      return reject(String('File type is not a picture'));
    }
    // Load the image
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      const image = new Image();
      image.onload = () => {
        // Resize the image
        const canvas = document.createElement('canvas');
        let { width, height } = image;
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        const dataUrl = canvas.toDataURL(contentType);

        return resolve(dataURLToBlob(dataUrl, filename));
      };
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
}
