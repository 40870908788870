import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import PageNotFound from './shared/PageNotFound';

import FormContainer from './forms/FormContainer';

// function requireAuthentication (Component) {
//   return () => (
//     <AuthenticatedComponent>
//       <Component />
//     </AuthenticatedComponent>
//   );
// }

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={FormContainer} />

      <Route path="*" component={PageNotFound} />
    </Switch>
  </BrowserRouter>
);

export default Router;
