import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { logout } from '../../store/modules/auth';

import MessageBox from './MessageBox';

import { isUserAgentMobile } from '../../utils/DeviceUtils';

@connect(
  state => ({
    //user: state.auth.user,
    menuIsTiny: state.data.config.menuIsTiny,
  }),
  { logout },
)
export default class BaseLayout extends React.PureComponent {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    children: PropTypes.node,
    menuIsTiny: PropTypes.bool,
    hideHeader: PropTypes.bool,

    title: PropTypes.string.isRequired,
    headerComponent: PropTypes.element,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
  };

  constructor () {
    super();
    this.state = {
      menuIsOpen: null,
    };
  }


  render () {
    const {
      title, headerComponent, hideHeader, className, headerClassName, children,
    } = this.props;
    return (
      <div id="wrapper" className={classnames(isUserAgentMobile ? 'mobile-app' : 'web-app', `env-${process.env.NODE_ENV}`)}>
        <MessageBox />

        <main>
          <div className={classnames('workspace', className)}>
            {children}
          </div>
          {process.env.NODE_ENV === 'staging' && (
            <div className="env-banner">
              !!! Environnement de recette !!!
            </div>
          )}
        </main>
      </div>
    );
  }
}
