import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, reset } from 'redux-form';
import validate from 'validate.js';
import OtherSection from '../elements/OtherSection';

import RadioField from '../elements/RadioField';
import InputField from '../elements/InputField';
import { formPosition } from '../../store/modules/forms';
import { wa_gfr_init } from '../../constants/tracking';

import { SINISTRE_CONCERNE } from '../../constants/Config';

function trackingDownload () {
  // window.wa_gfr = { ...wa_gfr_init };
  window._satellite.track('glb-declasinistre-telechargement');
}

function clearAndGoToNewClaims (goToNewClaims, formSelected) {
  console.log('on clear');
  Promise.resolve()
   .then(() => goToNewClaims(formSelected));
}

const Recapitulatif = ({
  handleSubmit,
  form,
  visibleForm,
  goToPrevious,
  goToNewClaims,
  resetForm,
  soussigneName,
  downloadPDFLink,
  loadingForm,
  isAuto
}) =>  (
  <div>

    <form
      className={visibleForm === formPosition.indexOf('validationForm') ? 'grid' : 'hidden'}
      onSubmit={handleSubmit}
    >
      <div className="col-12">
        {/* dommage subi */}
        Je soussigné(e)
        <Field
          maxLength="50"
          name="soussigne"
          component={InputField}
          label=""
          placeholder="Prénom Nom"
          className="field field-signature"
        />
          certifie que les informations déclarées dans le présent formulaire
          sont sincères et exactes.
      </div>

      <div className="btn-group between">
        <button className="btn btn-primary" type="button" onClick={() => goToPrevious()}>
          <i className="far fa-angle-left" />
          <span>Précédent</span>
        </button>
        <button className="btn btn-primary" type="submit">
          <span>Valider</span>
          <i className="far fa-angle-right" />
        </button>
      </div>
    </form>

    <div className={visibleForm === formPosition.indexOf('confirmation') && soussigneName !== '' ? 'validationFinale' : 'hidden'}>
      <p>
        Je soussigné(e), { soussigneName }, certifie que les informations déclarées dans le présent formulaire sont sincères et exactes.
      </p>
      {!loadingForm && downloadPDFLink ? (
        <div className="download-block">
          <p>
            Nous vous tiendrons informé(e) de la suite donnée à votre déclaration dans les meilleurs délais.
          </p>
          <div className="btn-group center">
            <a onClick={() => trackingDownload()} className="btn btn-primary" href={downloadPDFLink} target="_blank">
              TÉLÉCHARGEZ VOTRE DÉCLARATION
            </a>
          </div>
        </div>
      ) : (
        <div className="download-block">
          <p>
            Votre déclaration est en cours d'envoi, merci de patienter
            <br />
            ...
          </p>
        </div>
      )}
      <div className="col-12">
        <br />
        <div className="question-title">
          Vous souhaitez déclarer un autre sinistre ?
        </div>
        <div className="grid-center">
          <div className="col-3">
            <button className="btn final" type="button" onClick={() => { resetForm('dommagesForm'); clearAndGoToNewClaims(goToNewClaims, 'Dommages_aux_Biens'); }}>
              <span>Dommages aux Biens</span>
            </button>
          </div>
          <div className="col-3">
            <button className="btn final" type="button" onClick={() => { resetForm('rcpj'); clearAndGoToNewClaims(goToNewClaims, 'Responsabilite_civile'); }}>
              <span>Responsabilité civile</span>
            </button>
          </div>
          <div className="col-3">
            <button className="btn final" type="button" onClick={() => { resetForm('rcpj'); clearAndGoToNewClaims(goToNewClaims, 'Protection_juridique'); }}>
              <span>Protection Juridique</span>
            </button>
          </div>
          {isAuto === true && (
            <div className="col-3">
              <button className="btn final" type="button" onClick={() => { resetForm('automobilesForm'); clearAndGoToNewClaims(goToNewClaims, 'Automobile'); }}>
                <span>  Automobile   </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

Recapitulatif.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.string,
  visibleForm: PropTypes.number,
  soussigneName: PropTypes.string,
  goToPrevious: PropTypes.func.isRequired,
  goToNewClaims: PropTypes.func.isRequired,
  downloadPDFLink: PropTypes.string,
  loadingForm: PropTypes.bool,
  isAuto: PropTypes.bool,
};

const resetFormDispatch = dispatch => {
  return {
    resetForm: (form) => dispatch(reset(form)),
  };
};

export default connect(
  state => ({
    isAuto: (state.data.entities.caisse && state.data.entities.caisse.AUTO) || false,
    loadingForm: state.data.loading.forms, // pull initial values from account reducer
    initialValues: state.data.entities.forms.validationForm || {},
  }),
  resetFormDispatch,
)(reduxForm({
  form: 'validationForm',
  enableReinitialize: true,

  validate: (values = {}) => {
    const errors = {};
    errors.soussigne = validate.single(values.soussigne, {
      presence: { message: '^Merci de préciser votre prénom et nom' },
    });
    return errors;
  },
})(Recapitulatif));
