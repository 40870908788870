import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './icons-button.scss';

const IconButton = ({ icon, fontIcon, title, type, className, onClick }) => (
  <button onClick={onClick || null} className={className || 'btn'} title={title} type={type || 'button'}>
    { fontIcon ?
      <i className={classNames('fi', fontIcon)} />
      :
      <span className={classNames('bt-action-icon', icon)} />
    }
  </button>
);

IconButton.propTypes = {
  icon: PropTypes.string,
  fontIcon: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default IconButton;
