import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { map } from 'lodash';
import validate from 'validate.js';

import InputField from '../elements/InputField';
import OtherSection from '../elements/OtherSection';
import OtherSelect from '../elements/OtherSelect';
import RadioField from '../elements/RadioField';
import SelectField from '../elements/SelectField';
import TextareaField from '../elements/TextareaField';
import FieldArrayTabForm from './FieldArrayTabForm';
import { formPosition } from '../../store/modules/forms';
import ContenuEndommageFields from './ContenuEndommageFields';
import { MESSAGE_ERRORS, OUI_NON, CHOIX_GARANTIE, TYPE_TIERS, ETES_VOUS, MONTANT_DOMMAGE, SINISTRE_CONCERNE } from '../../constants/Config';


const DommagesAuxBiensForm = ({ handleSubmit, form, visibleForm, goToPrevious, typeSinistre }) => (
  <form
    className={visibleForm === formPosition.indexOf('dommagesForm') ? 'grid' : 'hidden'}
    onSubmit={handleSubmit}
  >
    <div className="col-12">
      <h3>
        Circonstances de votre sinistre { SINISTRE_CONCERNE[typeSinistre] }
      </h3>
    </div>
    <div className="col-12">
      <OtherSection
        sectionName="dommageConcerne"
        formName={form}
        choiceComponent={RadioField} // Or RadioField or InputField
        label="Votre dommage concerne ? *"
        options={CHOIX_GARANTIE}
        className="field field-radio"
        classNameGroup="field-radio-list field-radio-list-buttons col-12"
        sectionClassName="grid"
        otherSections={{
          other: () => (
            <Field
              maxLength="255"
              name="nomDommageConcerne"
              component={InputField}
              type="text"
              label="Dommage concerné *"
              className="field field-textarea col-12"
            />
          ),
        }}
      />
    </div>

    <div className="col-12">
      <Field
        maxLength="2000"
        name="circonstances_evenement"
        placeholder="Détaillez les circonstances de l'événement (Bâtiment(s) concerné(s) et description succincte)"
        component={TextareaField}
        label="Circonstances détaillées de l'événement (Bâtiment(s) concerné(s) et description succincte) *"
        className="field field-textarea"
      />
    </div>

    <div className="col-12">
      <OtherSection
        sectionName="oui_non_tiers_en_cause"
        formName={form}
        choiceComponent={RadioField} // Or RadioField or InputField
        label="Y a-t-il un tiers en cause ? *"
        options={OUI_NON}
        className="field field-radio"
        classNameGroup="field-radio-list field-radio-list-styled col-12"
        sectionClassName="grid"
        otherSections={{
          true: () => (
            <div className="grid col-11" data-push-left="off-1">

              <Field
                name="identite_addresse_tiers"
                component={TextareaField}
                label="Identité et adresse du tiers *"
                placeholder="Identité et adresse du tiers"
                className="field field-textarea col-12"
                maxLength="2000"
              />
              <Field
                name="identite_addresse_assureur"
                component={TextareaField}
                label="Son assureur (Identité, adresse, N° contrat, N° de sinistre...) *"
                placeholder="Son assureur (Identité, adresse, N° contrat, N° de sinistre...) "
                className="field field-textarea col-12"
                maxLength="2000"
              />
            </div>
          ),
        }}
      />
    </div>
    <div className="col-12">
      <Field
        name="montant_dommage"
        component={RadioField}
        type="text"
        label="A quel montant estimez-vous vos dommages ? *"
        className="field field-radio"
        classNameGroup="field-radio-list field-radio-list-buttons col-12"
        options={MONTANT_DOMMAGE}
      />
    </div>

    <div className="col-12">
      <OtherSection
        sectionName="etes_vous"
        formName={form}
        choiceComponent={RadioField} // Or RadioField or InputField
        label="Sur le(s) bien(s) concerné(s), êtes-vous ? *"
        options={ETES_VOUS}
        className="field field-radio"
        classNameGroup="field-radio-list field-radio-list-buttons col-12"
        sectionClassName="grid"
        otherSections={{}}
      />
    </div>

    <div className="col-12">
      <OtherSection
        sectionName="oui_non_contenu_endommage"
        formName={form}
        choiceComponent={RadioField} // Or RadioField or InputField
        label="Dans ces bâtiments, y a-t-il du contenu endommagé ? *"
        options={OUI_NON}
        className="field field-radio"
        classNameGroup="field-radio-list field-radio-list-styled col-12"
        sectionClassName="grid"
        otherSections={{
          true: () => (
            <FieldArray
              name="tableau_dommage"
              component={FieldArrayTabForm}
              ComponentField={ContenuEndommageFields}
              removable
              addLabel="+ Ajouter un contenu"
            />
          ),
        }}
      />
    </div>

    <div className="btn-group between">
      <button className="btn btn-primary" type="button" onClick={() => goToPrevious()}>
        <i className="far fa-angle-left" />
        <span>Précédent</span>
      </button>
      <button className="btn btn-primary" type="submit">
        <span>Suivant</span>
        <i className="far fa-angle-right" />
      </button>
    </div>

  </form>
);

DommagesAuxBiensForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  visibleForm: PropTypes.string.isRequired,
  goToPrevious: PropTypes.func.isRequired,
  typeSinistre: PropTypes.string.isRequired,
};

export default reduxForm({
  form: 'dommagesForm',
  enableReinitialize: true,
  validate: (values = {}) => {
    const errors = {
      oui_non_tiers_en_cause: {
        choix_type_tiers: {},
      },
      oui_non_contenu_endommage: {
        tableau_dommage: [],
      },
    };
    errors.dommageConcerne = validate(values.dommageConcerne, {
      choice: {
        presence: { message: '^Merci de préciser le dommage concerné' },
      },
    });
    if (values.dommageConcerne && values.dommageConcerne.choice === 'other') {
      errors.dommageConcerne = validate(values.dommageConcerne, {
        nomDommageConcerne: {
          presence: { message: '^Merci de préciser le dommage concerné' },
          length: { maximum: 255, tooLong: MESSAGE_ERRORS.max },
        },
      });
    } else if (values.dommageConcerne && values.dommageConcerne.choice !== 'other') {
      errors.dommageConcerne = validate(values.dommageConcerne, {
        CirconstancesDommages: {
          length: { maximum: 2000, tooLong: MESSAGE_ERRORS.max },
        },
      });
    }
    errors.oui_non_tiers_en_cause = validate(values.oui_non_tiers_en_cause, {
      choice: {
        presence: { message: '^Merci de préciser si un tiers est en cause' },
      },
    });
    if (values.oui_non_tiers_en_cause && values.oui_non_tiers_en_cause.choice === 'true') {
      errors.oui_non_tiers_en_cause = validate(values.oui_non_tiers_en_cause, {
        identite_addresse_tiers: {
          presence: { message: '^Merci de préciser l’identité et l’adresse du tiers' },
          length: { maximum: 2000, tooLong: MESSAGE_ERRORS.max },
        },
        identite_addresse_assureur: {
          presence: { message: '^Merci de préciser l’identité et l’adresse de son assureur' },
          length: { maximum: 2000, tooLong: MESSAGE_ERRORS.max },
        },
      });
    }
    errors.circonstances_evenement = validate.single(values.circonstances_evenement, {
      presence: { message: '^Merci de préciser les circonstances de l’événement' },
      length: { maximum: 2000, tooLong: MESSAGE_ERRORS.max },
    });
    errors.montant_dommage = validate.single(values.montant_dommage, {
      presence: { message: '^Merci de préciser l’estimation des dommages' },
    });
    errors.batiment_concernes = validate.single(values.batiment_concernes, {
      presence: { message: '^Merci de préciser le(s) bâtiment(s) concerné(s) et la description succincte des dommages' },
    });
    errors.etes_vous = validate(values.etes_vous, {
      choice: {
        presence: { message: '^Merci de préciser' },
      },
    });
    errors.oui_non_contenu_endommage = validate(values.oui_non_contenu_endommage, {
      choice: {
        presence: { message: '^Merci de préciser si des contenus sont concernés par le sinistre' },
      },
    });
    if (values.oui_non_contenu_endommage && values.oui_non_contenu_endommage.choice === 'true') {
      errors.oui_non_contenu_endommage = {
        ...errors.oui_non_contenu_endommage,
        tableau_dommage: map(
          values.oui_non_contenu_endommage.tableau_dommage,
          values => ContenuEndommageFields.validate(values)
        ),
      }
    }
    return errors;
  },
})(DommagesAuxBiensForm);
