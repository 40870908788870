import { get } from 'lodash';

import { CALL_API } from '../Schemas';
import tracking from '../../constants/tracking';
import { getJSONFromUrlParams } from '../../utils/URLParser';


const SAVE_FORM = 'cdwa/forms/SAVE_FORM';
const SAVE_FORMVALUES_REQUEST = 'cdwa/forms/SAVE_FORMVALUES_REQUEST';
const SAVE_FORMVALUES_SUCCESS = 'cdwa/forms/SAVE_FORMVALUES_SUCCESS';
const SAVE_FORMVALUES_FAILURE = 'cdwa/forms/SAVE_FORMVALUES_FAILURE';
const GO_TO_PREVIOUS = 'cdwa/forms/GO_TO_PREVIOUS';
const GO_TO_FORM = 'cdwa/forms/GO_TO_FORM';
const GO_TO_NEW_CLAIMS = 'cdwa/forms/GO_TO_NEW_CLAIMS';
const SEND_CLAIM_REQUEST = 'cdwa/forms/SEND_CLAIM_REQUEST';
const SEND_CLAIM_SUCCESS = 'cdwa/forms/SEND_CLAIM_SUCCESS';
const SEND_CLAIM_FAILURE = 'cdwa/forms/SEND_CLAIM_FAILURE';

export const formPosition = [
  'coordonnesForm',
  'sinistreForm',
  'dommagesForm',
  'validationForm',
  'confirmation',
];


export const formsActionsHandlers = {
  [SAVE_FORM]: (state, action) => {
    return ({
      ...state,
      entities: {
        ...state.entities,
        forms: {
          ...state.entities.forms,
          [action.formName]: action.values,
        },
      },
      config: {
        ...state.config,
        visibleForm: state.config.visibleForm + 1,
      },
    })
  },
  [SAVE_FORMVALUES_SUCCESS]: (state, action) => {
    return ({
      ...state,
      entities: {
        ...state.entities,
        caisse: {
          ...action.response.caisse,
        },
      },
    });
  },
  [SAVE_FORMVALUES_FAILURE]: (state, action) => ({
    ...state,
  }),
  [GO_TO_PREVIOUS]: state => ({
    ...state,
    config: {
      ...state.config,
      visibleForm: state.config.visibleForm - 1,
    },
  }),

  [GO_TO_NEW_CLAIMS]: (state, action) => {
    return ({
      ...state,
      entities: {
        ...state.entities,
        forms: {
          coordonnesForm: state.entities.forms.coordonnesForm,
          sinistreForm: {
            sinistre_concerne: {
              choice: action.claims,
            }
          }
        },
      },
      config: {
        ...state.config,
        visibleForm: 1,
      },
    })},

  [GO_TO_FORM]: (state, action) => ({
    ...state,
    config: {
      ...state.config,
      visibleForm: formPosition.indexOf(action.formName),
    },
  }),
  [SEND_CLAIM_REQUEST]: (state, action) => ({
    ...state,
    loading: {
      ...state.loading,
      forms: true,
    },
  }),
  [SEND_CLAIM_SUCCESS]: (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      downloadPDFLink: get(action, 'response.claim.pdf_url'),
    },
    loading: {
      ...state.loading,
      forms: false,
    },
  }),
};

// call when u want to save the values of different form
export function saveForm (formName, values, visibility) {
  tracking(formName, values);
  return {
    type: SAVE_FORM,
    formName,
    values,
    visibility,
  };
}

// call when u want to get the caisse of db ( TODO need to change the title .....)
export function saveFormValues () {
  const params = getJSONFromUrlParams(true);
  return {
    [CALL_API]: {
      types: [SAVE_FORMVALUES_REQUEST, SAVE_FORMVALUES_SUCCESS, SAVE_FORMVALUES_FAILURE],
      method: 'GET',
      endpoint: `/claims/?caisse=${params.caisse}`,
    },
  };
}

export function goToPrevious () {
  return {
    type: GO_TO_PREVIOUS,
  };
}

export function goToNewClaims (claims) {
  return {
    type: GO_TO_NEW_CLAIMS,
    claims,
  };
}

export function goToForm (formName) {
  return {
    type: GO_TO_FORM,
    formName,
  };
}

export function submitForm (valuesForm) {
  console.log('on lance le submit valuesForm :::::::: ', valuesForm);
  return {
    [CALL_API]: {
      types: [SEND_CLAIM_REQUEST, SEND_CLAIM_SUCCESS, SEND_CLAIM_FAILURE],
      method: 'POST',
      endpoint: '/claims',
      body: (state) => {
        const data = {
          coordonnesForm: state.data.entities.forms.coordonnesForm,
          sinistreForm: state.data.entities.forms.sinistreForm,
          validationForm: state.data.entities.forms.validationForm,
          automobilesForm: state.data.entities.forms.automobilesForm,
        };
        switch (state.data.entities.forms.sinistreForm.sinistre_concerne.choice) {
        /*  case 'Automobile':
          data.automobilesForm = state.data.entities.forms.automobilesForm;
          break;*/
        case 'Dommage_aux_biens':
          data.DommagesAuxBiensForm = state.data.entities.forms.DommagesAuxBiensForm;
          break;
        default:
          data.rcpj = state.data.entities.forms.rcpj;
          break;
        }
        const params = getJSONFromUrlParams(true);
        data.trigramme = params.caisse;
        return data;
      },
    },
  };
}
