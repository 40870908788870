import React from 'react';
import PropTypes from 'prop-types';

const FieldError = ({
  touched, submitFailed, error, warning,
}) => (
  (touched || submitFailed)
    ? (error && <div className="field-error">{Array.isArray(error) ? error.join(', ') : error}</div>)
      ||
      (warning && <div className="field-warning">{warning}</div>)
      || null
    : null
);

FieldError.propTypes = {
  touched: PropTypes.bool,
  submitFailed: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  warning: PropTypes.string,
};

export default FieldError;
