import { CALL_API } from '../Schemas';

const LOGIN = 'cdwa/auth/LOGIN';
const LOGIN_SUCCESS = 'cdwa/auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'cdwa/auth/LOGIN_FAIL';
const LOGOUT = 'cdwa/auth/LOGOUT';
export const LOGOUT_SUCCESS = 'cdwa/auth/LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'cdwa/auth/LOGOUT_FAIL';
const UPDATE_PASSWORD_REQUEST = 'cdwa/auth/UPDATE_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'cdwa/auth/UPDATE_SUCCESS';
const UPDATE_PASSWORD_FAILURE = 'cdwa/auth/UPDATE_FAILURE';

export default function reducer (state = {}, action = {}) {
  switch (action.type) {
  case LOGIN_SUCCESS:
    return {
      ...state,
      loggingIn: false,
      user: action.response,
    };
  case LOGIN_FAIL:
    return {
      ...state,
      loggingIn: false,
      user: null,
    };
  case LOGOUT:
    return {
      ...state,
      loggingOut: true,
    };
  case LOGOUT_SUCCESS:
    return {
      ...state,
      loggingOut: false,
      user: null,
    };
  case LOGOUT_FAIL:
    return {
      ...state,
      loggingOut: false,
    };
  default:
    return state;
  }
}

export function login (email, password) {
  return {
    [CALL_API]: {
      types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
      method: 'POST',
      endpoint: '/accounts/login',
      body: {
        email,
        password,
      },
    },
  };
}

export function logout () {
  return {
    type: LOGOUT_SUCCESS,
  };
}

export function recoverPassword (email) {
  return {
    [CALL_API]: {
      types: [UPDATE_PASSWORD_REQUEST, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAILURE],
      method: 'PUT',
      endpoint: '/accounts/password',
      body: {
        email,
      },
    },
  };
}
