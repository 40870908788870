import React from 'react';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import CoordonnesForm from './CoordonnesForm';
import SinistreForm from './SinistreForm';
import DommagesAuxBiensForm from './DommagesAuxBiensForm';
import Rcpj from './Rcpj';
import AutomobilesForm from './AutomobilesForm';
import Recapitulatif from './Recapitulatif';
import { formPosition } from '../../store/modules/forms';

function styleClassName (form, visibleForm) {
  if (formPosition.indexOf(form) < visibleForm && visibleForm !== formPosition.indexOf('confirmation')) {
    return 'btn-form-title done';
  }
  if (formPosition.indexOf(form) === visibleForm) {
    return 'btn-form-title active';
  }
  return 'btn-form-title';
}
const DropdownFormsView = ({
  onSubmitForm,
  onFormChange,
  submitForm,
  visibleForm,
  reset,
  goToPrevious,
  goToNewClaims,
  goToForm,
  typeSinistre,
  typeContrat,
  soussigneName,
  downloadPDFLink,
}) => (
  <div className="full-width">
    <div className="box">
      <button
        type="button"
        onClick={visibleForm < formPosition.indexOf('coordonnesForm') || visibleForm === formPosition.indexOf('confirmation') ? null : () => goToForm('coordonnesForm')}
        className={styleClassName('coordonnesForm', visibleForm)}>
        <span className="circle"></span>
        <h1>
          Vos coordonnées
        </h1>
      </button>
      <div className="box-form">
        <CoordonnesForm
          onSubmit={values => onSubmitForm('coordonnesForm', values)}
          onFormChange={() => onFormChange('coordonnesForm')}
          goToPrevious={goToPrevious}
          visibleForm={visibleForm}
        />
      </div>
    </div>
    <div className="box">
      <button
        type="button"
        className={styleClassName('sinistreForm', visibleForm)}
        onClick={visibleForm < formPosition.indexOf('sinistreForm') || visibleForm === formPosition.indexOf('confirmation') ? null : () => goToForm('sinistreForm')}>
        <span className="circle"></span>
        <h1>
            Votre sinistre
        </h1>
      </button>
      <div className="box-form">
        <SinistreForm
          onSubmit={values => onSubmitForm('sinistreForm', values)}
          onFormChange={() => onFormChange('sinistreForm')}
          goToPrevious={goToPrevious}
          visibleForm={visibleForm}
        />
      </div>
    </div>
    <div className="box">
      <button
        type="button"
        className={styleClassName('dommagesForm', visibleForm)}
        onClick={visibleForm < formPosition.indexOf('dommagesForm') || visibleForm === formPosition.indexOf('confirmation') ? null : () => goToForm('dommagesForm')}>
        <span className="circle"></span>
        <h1>
          Vos dommages
          {typeSinistre === 'Responsabilite_civile' && (' Responsabilité Civile')}
          {typeSinistre === 'Protection_juridique' && (' Protection Juridique')}
          {typeSinistre === 'Dommage_aux_biens' && (' aux biens')}
          {!typeContrat && typeSinistre === 'Automobile' && (' Automobile') }
          {typeContrat && typeContrat === 'Autre' && typeSinistre === 'Automobile' &&  (' Automobile') }
          {typeContrat && typeContrat !== 'Autre' && typeSinistre === 'Automobile' &&  (' Automobile')  + ' ' + typeContrat}
        </h1>
      </button>
      <div className="box-form">
        { typeSinistre === 'Dommage_aux_biens' && (
          <DommagesAuxBiensForm
            onSubmit={values => onSubmitForm('DommagesAuxBiensForm', values)}
            onFormChange={() => onFormChange('DommagesAuxBiensForm')}
            goToPrevious={goToPrevious}
            visibleForm={visibleForm}
            typeSinistre={typeSinistre}
            initialValues={{
              oui_non_contenu_endommage: {
                tableau_dommage: [{}],
              },
            }}
          />
        )}
        {(typeSinistre === 'Protection_juridique' || typeSinistre === 'Responsabilite_civile') && (
          <Rcpj
            onSubmit={(values) => {
              onSubmitForm('rcpj', values)
             // .then(() => reset());
            }}
            onFormChange={() => onFormChange('rcpj')}
            goToPrevious={goToPrevious}
            visibleForm={visibleForm}
            typeSinistre={typeSinistre}
          />
        )}
        {typeSinistre === 'Automobile' && (
          <AutomobilesForm
            onSubmit={(values) => {
              onSubmitForm('automobilesForm', values)
             // .then(() => reset('Automobile'));
            }}
            onFormChange={() => onFormChange('automobilesForm')}
            goToPrevious={goToPrevious}
            goToNewClaims={goToNewClaims}
            visibleForm={visibleForm}
            typeSinistre={typeSinistre}
            initialValues={{
              blesses: {
                tableau_blesses: [{}],
              },
            }}
          />
        )}
      </div>
    </div>
    <div className="box">
      <button
        type="button"
        className={styleClassName('validationForm', visibleForm)}
        onClick={visibleForm < formPosition.indexOf('validationForm') || visibleForm === formPosition.indexOf('confirmation') ? null : () => goToForm('recapitulatif')}>
        <span className="circle"></span>
        <h1>
          Validation
        </h1>
      </button>
      <div className="box-form">
        <Recapitulatif
          onSubmit={values => onSubmitForm('validationForm', values).then(() => submitForm())}
          onFormChange={() => onFormChange('validationForm')}
          goToPrevious={goToPrevious}
          goToNewClaims={goToNewClaims}
          visibleForm={visibleForm}
          soussigneName={soussigneName}
          downloadPDFLink={downloadPDFLink}
        />
      </div>
    </div>
  </div>
);

DropdownFormsView.propTypes = {
  typeSinistre: PropTypes.string,
  typeContrat: PropTypes.string,
  onSubmitForm: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  goToPrevious: PropTypes.func.isRequired,
  goToNewClaims: PropTypes.func.isRequired,
  goToForm: PropTypes.func.isRequired,
  visibleForm: PropTypes.number.isRequired,
  soussigneName: PropTypes.string,
  downloadPDFLink: PropTypes.string,
};

export default connect(
  state => ({
    typeSinistre: formValueSelector('sinistreForm')(state, 'sinistre_concerne.choice'),
    typeContrat: formValueSelector('sinistreForm')(state, 'sinistre_concerne.Contrat'),
    soussigneName: formValueSelector('validationForm')(state, 'soussigne'),
    downloadPDFLink: state.data.entities.downloadPDFLink,
  }),
)(DropdownFormsView);
