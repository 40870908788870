import React from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, FormSection } from 'redux-form';
import { connect } from 'react-redux';

const OtherSection = ({
  choiceValue,
  sectionName,
  sectionClassName,
  choiceComponent,
  otherSections,
  ...componentProps
}) => (
  <FormSection name={sectionName} className={sectionClassName}>
    <Field name="choice" component={choiceComponent} {...componentProps} id={sectionName} />
    {otherSections[choiceValue] && (
      otherSections[choiceValue]()
    )}
  </FormSection>
);

OtherSection.propTypes = {
  choiceValue: PropTypes.string,
  sectionName: PropTypes.string.isRequired,
  sectionClassName: PropTypes.string,
  choiceComponent: PropTypes.func.isRequired,
  otherSections: PropTypes.objectOf(
    PropTypes.func.isRequired,
  ).isRequired,
};
export default connect(
  (state, { formName, sectionName }) => ({
    choiceValue: formValueSelector(formName)(state, `${sectionName}.choice`),
  }),
)(OtherSection);
