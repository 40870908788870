import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import moment from 'moment';

import App from './containers/App';

// Global / Default local for moment
moment.locale('fr');

function renderWithHotReload (RootElement) {
  render(
    <AppContainer>
      <RootElement />
    </AppContainer>,
    document.getElementById('root'),
  );
}

renderWithHotReload(App);

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./containers/App', () => {
    const ReplaceContainer = require('./containers/App');
    renderWithHotReload(ReplaceContainer);
  });
}
