import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import validate from 'validate.js';
import InputField from './InputField';
import SelectField from './SelectField';
import OtherSection from './OtherSection';


const OtherSelect = ({
  labelOther,
  selectedChoiceValue,
  ...componentProps
}) => (
  <OtherSection
    {...componentProps}
    choiceComponent={SelectField}
    otherSections={{
      [selectedChoiceValue]: () => (
        <Field name="other" component={InputField} type="text" label={labelOther} />
      ),
    }}
  />
);

OtherSelect.propTypes = {
  labelOther: PropTypes.string.isRequired,
  selectedChoiceValue: PropTypes.string.isRequired,
};

OtherSelect.validate = (values = {}, message = {}) => {
  const errors = {};
  if (!values.choice) errors.choice = message.choice;
  errors.other = validate.single(values.other, {
    presence: { message: message.other },
    length: { maximum: 30, tooLong: 'Ne peut pas depasser %{count} caracteres' },
  });

  return errors;
};

export default OtherSelect;
