
export const API_URL = process.env.NODE_ENV === 'development'
  ? `${window.location.protocol}//${window.location.hostname}:3333/api`
  : '/api';

export const DISABLE_CONFIRMATION_MODAL = (process.env.NODE_ENV === 'development');

export const DATE_FORMAT = 'DD/MM/YYYY';

export const RESIZE_IMAGE_MAX_SIZE = 1137;

export const AUTHORIZED_UPLOAD_IMAGES = [
  'image/gif',
  'image/jpeg',
  'image/png',
];

export const AUTHORIZED_UPLOAD_FILES = [
  'application/pdf',
];

export const OPT_COURTESY_TITLE = {
  mr: { short: 'M.', long: 'Monsieur' },
  mme: { short: 'Mme', long: 'Madame' },
};

export const OPT_ACCOUNT_ROLES = {
  ADMIN: 'Administrateur',
  MANAGER: 'Gestionnaire',
  TECHNICIAN: 'Technicien',
};

export const OPT_PHONE_TYPE = {
  home: 'Fixe',
  mobile: 'Mobile',
  other: 'Autres',
};

export const OPT_EMAIL_TYPE = {
  personal: 'Personnel',
  work: 'Professionnel',
  other: 'Autres',
};


// constantes application de sinistres

export const NOM_COLLECTIVITE = {
  Syndicat: 'Syndicat',
  Association: 'Association',
  Collectivite_territoriale: 'Collectivité territoriale',
  other: 'Autre',
};

export const INTERLOCUTEUR = {
  Maire: 'Maire',
  Président: 'Président',
  DGS: 'DGS',
  Directeur: 'Directeur',
  Secretaire_de_Mairie: 'Secrétaire de Mairie',
  other: 'Autre',
};

export const SINISTRE_CONCERNE = {
  Dommage_aux_biens: 'Dommages aux Biens',
  Responsabilite_civile: 'Responsabilité Civile',
  Protection_juridique: 'Protection Juridique',
  Automobile: 'Automobile',
};

export const SINISTRE_CONCERNE_2 = {
  Dommage_aux_biens: 'Dommages aux Biens',
  Responsabilite_civile: 'Responsabilité Civile',
  Protection_juridique: 'Protection Juridique',
};

export const CONTRAT_MISSION = {
  Mission: 'Mission',
  Flotte: 'Flotte',
  Autre: 'Autre',
};

export const CHOIX_GARANTIE = {
  Incendie: 'Incendie',
  Dommages_electriques: 'Dommages électriques',
  Vol: 'Vol',
  Tempete: 'Tempête',
  Degats_des_eaux: 'Dégâts des eaux',
  Bris_de_glace: 'Bris de glace',
  other: 'Autres',
};

export const TYPE_DOMMAGE = {
  Accident: 'Accident',
  Tentative_de_vol: 'Tentative de vol',
  Vol: 'Vol',
  Incendie: 'Incendie',
  Evenement_naturel: 'Événement naturel',
  Vandalisme: 'Vandalisme',
};

export const TYPE_TIERS = {
  Proprietaire: 'Propriétaire',
  Locataire: 'Locataire',
  Voisins: 'Voisin',
  other: 'Autre',
};

export const MONTANT_DOMMAGE = {
  Inf_1000: '< 1 000€',
  '1000_a_3000': 'Entre 1 000€ et 3 000€',
  '3000_a_15000': 'Entre 3 000€ et 15 000€',
  Sup_15000: '> 15 000€',
};

export const ETES_VOUS = {
  Propriétaire_occupant: 'Propriétaire occupant',
  Propriétaire_non_occupant: 'Propriétaire non occupant',
  Locataire: 'Locataire',
  Co_proprietaire: 'Co-propriétaire',
};

export const OUI_NON = {
  'true': 'Oui',
  'false': 'Non',
};

export const VEHICULE_LOCALISATION = {
  Domicile: 'À mon domicile',
  Garagiste: 'Chez le garagiste',
  Autres: 'Autres',
};

export const CORPOREL_MATERIEL = [
  {
    label: 'Corporel',
    value: 'Corporel',
  },
  {
    label: 'Matériel',
    value: 'Matériel',
  },
];

export const TYPE_CONTENU = {
  Mobilier: 'Mobilier',
  Materiel: 'Matériel',
  Marchandises: 'Marchandises',
  other: 'Autres',
};

export const TYPE_CONTENU_ARRAY = ['Mobilier', 'Matériel', 'Marchandises', 'Autres'];


export const MESSAGE_ERRORS = {
  min: '^Doit avoir au moins %{count} caractères',
  max: '^Ne peut pas depasser %{count} caractères',
};
