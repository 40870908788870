import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import validate from 'validate.js';

import InputField from '../elements/InputField';
import OtherSection from '../elements/OtherSection';
import CleaveField from '../elements/CleaveField';
import RadioField from '../elements/RadioField';
import SelectField from '../elements/SelectField';
import AddressPostalTownFields from './AddressPostalTownFields';
import { formPosition } from '../../store/modules/forms';
import infobulleimg from '../../../assets/images/infobulle.jpg';
import {
  NOM_COLLECTIVITE,
  OUI_NON,
  INTERLOCUTEUR,
  MESSAGE_ERRORS,
} from '../../constants/Config';

const infobulle = () => (
  <div className="infobulle-content">
    Vous trouverez votre identifiant internet ou N° de client sur :
    <ol>
      <li>
        Votre relevé d&apos;opérations assurance
      </li>
      <li>
        Vos attestations d&apos;assurance.
      </li>
    </ol>
    <br />
    <span>Exemple sur un avis d&apos;opérations</span>
    <img src={infobulleimg} alt="infobulle" />
  </div>
);

const CoordonnesForm = ({ handleSubmit, form, visibleForm }) => (
  <form
    className={visibleForm === formPosition.indexOf('coordonnesForm') ? 'grid' : 'hidden'}
    onSubmit={handleSubmit}
  >
    <div className="col-12">
      <Field
        name="collectivite"
        placeholder="Choisissez le type de collectivité"
        label="Collectivité *"
        component={SelectField}
        noResultsText="Aucun résultat trouvé"
        type="text"
        className="field"
        options={NOM_COLLECTIVITE}
      />
    </div>

    <div className="col-12">
      <Field
        name="nom_collectivite"
        label="Nom de la collectivité *"
        component={InputField}
        type="text"
        maxLength="50"
        className="field"
      />
    </div>

    <div className="col-12">
      <Field
        name="interlocuteur"
        label="Fonction du déclarant *"
        component={InputField}
        type="text"
        maxLength="50"
        className="field"
      />
    </div>

    <div className="col-12">
      <Field
        name="nom_interlocuteur"
        label="Nom du déclarant *"
        component={InputField}
        type="text"
        maxLength="50"
        className="field"
      />
    </div>

    <div className="col-12">
      {/* email */}
      <Field
        name="email"
        label="Email du déclarant : *"
        component={InputField}
        type="email"
        maxLength="255"
        className="field"
      />
    </div>

    <div className="col-12">
      {/* numero de telephone */}
      <Field
        name="telephone"
        label="Téléphone du déclarant *"
        placeholder="06 40 76 89 30"
        component={CleaveField}
        cleaveOptions={{ phone: true, phoneRegionCode: 'FR' }}
        type="tel"
        className="field field-tel"
      />
    </div>

    <div className="col-12">
      {/* Connais tu ton identifiant client ? */}
      <OtherSection
        sectionName="identification"
        formName={form}
        choiceComponent={RadioField} // Or RadioField or InputField
        label="Je connais mon identifiant client *"
        infobulle={infobulle()}
        options={OUI_NON}
        className="field field-radio"
        classNameGroup="field-radio-list field-radio-list-styled col-12"
        sectionClassName="grid"
        otherSections={{
          true: () => (
            <div className="col-12">
              <Field
                maxLength="8"
                name="identifiant_client"
                component={InputField}
                type="number"
                label="Identifiant client *"
                className="field"
              />
            </div>
          ),
          false: () => (
            <AddressPostalTownFields
              className="grid"
              sectionName="localisation_coordonnes"
              labelAdress="Adresse de la collectivité *"
            />
          ),
        }}
      />
    </div>
    <div className="btn-group right">
      <button className="btn btn-primary" type="submit">
        <span>Suivant</span>
        <i className="far fa-angle-right" />
      </button>
    </div>
  </form>
);

CoordonnesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  visibleForm: PropTypes.number,

};


export default reduxForm({
  form: 'coordonnesForm',
  validate: (values = {}) => {
    const errors = {
      identification: {
      },
      telephone: '',
    };
    // type collectivite est obligatoire et ne depasse pas 255 caracteres
    errors.collectivite = validate.single(values.collectivite, {
      presence: { message: '^Merci de préciser le type de collectivité' },
      length: { maximum: 255, tooLong: MESSAGE_ERRORS.max },
    });
    // nom collectivite est obligatoire et ne depasse pas 50 caracteres
    errors.nom_collectivite = validate.single(values.nom_collectivite, {
      presence: { message: '^Merci de préciser le nom de la collectivité' },
      length: { maximum: 50, tooLong: MESSAGE_ERRORS.max },
    });
    // type interlocuteur est obligatoire et ne depasse pas 255 caracteres
    errors.interlocuteur = validate.single(values.interlocuteur, {
      presence: { message: '^Merci de préciser la fonction de déclarant' },
      length: { maximum: 255, tooLong: MESSAGE_ERRORS.max },
    });
    // nom interlocueteur est obligatoire et ne depasse pas 50 caracteres
    errors.nom_interlocuteur = validate.single(values.nom_interlocuteur, {
      presence: { message: '^Merci de préciser le nom du déclarant' },
      length: { maximum: 50, tooLong: MESSAGE_ERRORS.max },
    });
    // email est obligatoire et ne depasse pas 255 caracteres
    errors.email = validate.single(values.email, {
      presence: { message: '^Merci de préciser l’adresse email du déclarant' },
      email: { message: '^Merci de renseigner une adresse email valide' },
      length: { maximum: 255, tooLong: MESSAGE_ERRORS.max },
    });
    // telephone est obligatoire et doit respecter le format de telephone en france
    errors.telephone = validate.single(values.telephone, {
      presence: { message: '^Merci de préciser le numéro de téléphone du déclarant' },
      format: {
        pattern: /^(0[1-9]|\+[0-9]{2,5})( [0-9]{2}){4}/,
        message: "^Le numero de telephone n'est pas valide",
      },
    });
    // dire si jai un identifiant est obligatoire
    errors.identification = validate(values.identification, {
      choice: {
        presence: { message: '^Cette question est obligatoire' },
      },
    });
    // Si jai un identifiant lidentifiant est obligatoire
    if (values.identification && values.identification.choice === 'true') {
      errors.identification = validate(values.identification, {
        identifiant_client: {
          presence: { message: '^Merci de préciser l’identifiant client' },
          length: { is: 8, wrongLength: "^Votre identifiant n'est pas au bon format" },
        },
      });
      // Si je nai pas didentifiant je dois renseigner mes coordennes de localisation
    } else if (values.identification && values.identification.choice === 'false') {
      errors.identification = AddressPostalTownFields.validate(values.identification, 'localisation_coordonnes');
    }
    return errors;
  },
})(CoordonnesForm);
