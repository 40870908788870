import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { setMessage } from '../../store/modules/globals';

const MessageBox = ({ message, setMessage }) => {
  if (message.delay && message.delay > 0) {
    setTimeout(setMessage, message.delay);
  }
  return (
    <div id="message-box" className={classNames('message-box', message.messageType)}>
      { message && message.text && message.messageType ? (
        <div className="message-content">
          <div className="text-message">
            <i className="fi fi-warning" />
            {message.text}
          </div>
          <button onClick={() => setMessage()}><i className="fas fa-times" /></button>
        </div>
      )
      : null }
    </div>
  );
};

MessageBox.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string.isRequired,
    messageType: PropTypes.string.isRequired,
  }),
  setMessage: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    message: state.data.message,
  }),
  { setMessage },
)(MessageBox);
