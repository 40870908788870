import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { map } from 'lodash';
import moment from 'moment';
import validate from 'validate.js';
import DateField from '../elements/DateField';
import SelectField from '../elements/SelectField';
import InputField from '../elements/InputField';
import fetch from 'isomorphic-fetch';
import { API_URL } from '../../constants/Config';

const ContenuEndommageFields = ({ field, onClickRemove, fieldIndex, labelCpVille }) => (

  <tr>
    <td className="firstname-name">
      <Field
        name={`${field}.nom`}
        component={InputField}
        className="field"
        placeholder="..."
      />
    </td>
    <td className="injury">
      <Field
        name={`${field}.blessure`}
        component={InputField}
        placeholder="..."
        className="field"
      />
    </td>
    <td className="">
      <Field
        name={`${field}.adresse`}
        component={InputField}
        placeholder="..."
        searchPromptText=""
        className="field"
      />
      {fieldIndex >= 1 && onClickRemove && (
        <button type="button" onClick={onClickRemove}>
          <span>Supprimer</span>
        </button>
      )}
    </td>
  </tr>
);

ContenuEndommageFields.propTypes = {
  field: PropTypes.string.isRequired,
  onClickRemove: PropTypes.func.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  labelCpVille: PropTypes.string.isRequired,

};

ContenuEndommageFields.validate = (values = {}) => {
  const errors = { };
  const date = moment(values.date, 'DD/MM/YYYY');
  if (moment().isBefore(date)) errors.date = 'La date d’achat doit être antérieure à la date du jour';
  if (!values.contenu) errors.contenu = 'Merci de préciser le type de contenu';
  if (!values.description && values.date) errors.description = 'Merci de préciser le descriptif du bien et des dommages';


  return errors;
};

export default ContenuEndommageFields;
