import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

function textToEditorState (text = '') {
  const contentState = ContentState.createFromBlockArray(htmlToDraft(text).contentBlocks);
  return EditorState.createWithContent(contentState);
}

function editorStateToText (editorState) {
  return draftToHtml(convertToRaw(editorState.getCurrentContent()));
}

export default class WysiwygTextarea extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string,
    placeholder: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.string),
  }

  constructor (props) {
    super();
    this.state = {
      editorState: textToEditorState(props.text),
    };
  }

  componentWillReceiveProps (nextProps) {
    this.setState({ editorState: textToEditorState(nextProps.text) });
  }

  onBlur () {
    this.props.onBlur(editorStateToText(this.state.editorState));
  }

  render () {
    return (
      <Editor
        editorState={this.state.editorState}
        onEditorStateChange={e => this.setState({ editorState: e })}
        toolbarHidden={this.props.options && this.props.options.length === 0}
        onBlur={() => this.onBlur()}
        placeholder={this.props.placeholder}
        toolbar={{
          options: this.props.options || ['inline', 'colorPicker'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
        }}
      />
    );
  }
}
