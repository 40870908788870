import { schema } from 'normalizr';

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/gaearon/normalizr

const missionSchema = new schema.Entity('missions', {}, { idAttribute: 'mission_id' });
const visitSchema = new schema.Entity('missions', {}, { idAttribute: 'visit_id' });
const accountSchema = new schema.Entity('accounts', {}, { idAttribute: 'account_id' });
const commentSchema = new schema.Entity('comments', {}, { idAttribute: 'id' });
const contactSchema = new schema.Entity('contacts', {}, { idAttribute: 'contact_id' });

// Schemas for API responses.
export const Schemas = {
  ACCOUNT: accountSchema,
  ACCOUNT_ARRAY: new schema.Array(accountSchema),
  MISSION: missionSchema,
  MISSION_ARRAY: new schema.Array(missionSchema),
  VISIT: visitSchema,
  VISIT_ARRAY: new schema.Array(visitSchema),
  COMMENT: commentSchema,
  COMMENT_ARRAY: new schema.Array(commentSchema),
  CONTACT: contactSchema,
  CONTACT_ARRAY: new schema.Array(contactSchema),
};
// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');
